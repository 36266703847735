





























































































































import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import EventBus from '@/eventBus';
import axios from 'axios';
import { get, pick, set, uniqBy } from 'lodash';
import CoModal from '../co-modal/CoModal.vue';
import CoEditor from '../../Molecules/co-editor/CoEditor.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';

import CoLinkPreview from '../co-link-preview/CoLinkPreview.vue';
import CoContentPreview from '../co-content-preview/CoContentPreview.vue';

export default {
    name: 'CoPostEditor',
    components: {
        CoModal,
        CoEditor,
        CoButton,
        CoThumbnail,
        CoIcon,
        CoLinkPreview,
        CoContentPreview,
    },
    props: {
        user: {
            type: Object,
            default: () => ({}),
        },
        parent: {
            type: Object,
            default: () => ({}),
        },
        parentType: {
            type: String,
            validator: (value) => ['project', 'post', 'event', 'job'].includes(value),
            default: null,
        },
        showOnMount: {
            type: Boolean,
            default: false,
        },
        prefilledContent: {
            type: Object,
            default: null,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        editContent: {
            type: Object,
            default: () => ({}),
        },
        editMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            content: {},
            hasContent: false,
            loading: false,
            links: [],
            featured: [],
        };
    },
    watch: {
        //deep watch changes to content parameter
        content: {
            handler: function (newVal, oldVal) {
                // if newVal is Object and has keys check each key for content
                if (newVal && typeof newVal === 'object') {
                    this.hasContent = get(this.content, 'Text', '').length > 0;
                    this.links = uniqBy(get(this.content, 'Links', []), 'href');
                    this.featured = uniqBy(get(this.content, 'Featured', []), 'href');
                }
            },
            deep: true,
        },
    },
    computed: {
        isMobile() {
            return get(this, '$isMobile', false) || this.mobile;
        },
    },
    mounted() {
        if (this.showOnMount) this.show();
    },
    methods: {
        launch(prefilledContent: Object = null, editMode = false) {
            this.show(prefilledContent, editMode);
        },
        show(prefilledContent: Object = null, editMode = false) {
            this.prefilledContent = prefilledContent;
            this.editMode = editMode;
            this.$refs.postmodal.show();
        },
        discard() {
            if (this.hasContent && !window.confirm(this.$t('messages.unsavedwarning'))) return;
            //reset content and hide modal
            this.content = {};
            this.loading = false;
            this.$refs.postmodal.hide();
            this.$emit('discard');
        },
        uploadImages() {
            return new Promise((resolve, reject) => {
                //return null if no files
                if (!this.content.Files || !Array.isArray(this.content.Files) || this.content.Files.length === 0)
                    resolve(null);

                //upload all images and return the urls
                const promises = this.content.Files.map((file) => {
                    // only upload if a file is present
                    if (file && file.file) {
                        const data = new FormData();
                        data.append('file', file.file);
                        return axios.post('/upload/image/post', data, {
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'image/*',
                            },
                        });
                    } else if (file && file.url) {
                        // if url exists, return the url and do not upload again
                        return Promise.resolve({
                            data: {
                                url: file.url,
                            },
                        });
                    }
                });

                Promise.all(promises)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            });
        },
        async postSubmit(isUpdate = false) {
            if (get(this, 'prefilledContent.Type', '') === 'project-update') {
                this.pageUpdateSubmit(isUpdate);
                return;
            }
            this.loading = true;
            try {
                // upload images
                const imageUpload =
                    this.content.Files && this.content.Files.length > 0 ? await this.uploadImages() : null;
                const imageURLs = imageUpload ? imageUpload.map((response) => response.data.url) : null;

                // create post
                axios({
                    method: isUpdate ? 'PUT' : 'POST',
                    url: '/post',
                    data: {
                        ...pick(this.prefilledContent, ['ID', 'AuthorID', 'CreatedAt', 'Slug', 'Type']),
                        UpdatedAt: isUpdate ? Math.round(new Date().getTime() / 1000).toString() : null,
                        Text: this.content.Html || '',
                        Images: imageURLs,
                        Version: '1',
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        EventBus.$emit('INFO', {
                            Message: this.$t('labels.postpublished'),
                            Details: '',
                        });

                        // reset content and hide modal
                        this.content = {};
                        this.hasContent = false;
                        this.discard();

                        // emit event to parent
                        const obj = {
                            object: {
                                ...get(response, 'data', {}),
                                Author: this.user || {},
                                Type: 'post',
                            },
                            type: 'post',
                            score: get(response, 'data.CreatedAt', null),
                            createdBy: get(response, 'data.AuthorID', null),
                        };
                        set(
                            obj,
                            'object.Images',
                            get(obj, 'object.Images', []).map((image) => ({
                                ImageURL: image,
                            }))
                        );
                        isUpdate ? this.$emit('updated', obj) : this.$emit('created', obj);
                    })
                    .catch((error) => {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not create post. Please try again later.',
                            Details: null,
                        });
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                // error handling for image uploads
                const errorMsg = {
                    Message: 'Could not upload one or more images, please try again or report a bug.',
                    Details: error.response.data,
                };
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message === 'Request Entity Too Large'
                ) {
                    errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                }
                EventBus.$emit('ERROR', errorMsg);
                console.error(error);
                this.loading = false;
            }
        },
        async pageUpdateSubmit(isUpdate = false) {
            this.loading = true;
            try {
                // upload images
                const imageUpload =
                    this.content.Files && this.content.Files.length > 0 ? await this.uploadImages() : null;
                const imageURLs = imageUpload ? imageUpload.map((response) => response.data.url) : null;

                let data = this.prefilledContent;
                data.Text = this.content.Html || '';
                data.ImageURLs = imageURLs;
                data.Version = '1';
                data.Updated = isUpdate ? Math.round(new Date().getTime() / 1000).toString() : null;

                // create/update page update
                axios({
                    method: isUpdate ? 'PUT' : 'POST',
                    url: '/project/update',
                    data,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        EventBus.$emit('INFO', {
                            Message: this.$t('labels.postpublished'),
                            Details: '',
                        });

                        // reset content and hide modal
                        this.content = {};
                        this.hasContent = false;
                        this.discard();

                        // emit event to parent
                        const obj = {
                            object: {
                                ...get(response, 'data', {}),
                                Author: this.user || {},
                                Type: 'project-update',
                            },
                            type: 'project-update',
                            score: get(response, 'data.CreatedAt', null),
                            createdBy: get(response, 'data.UserID', null),
                        };
                        set(
                            obj,
                            'object.Images',
                            get(obj, 'object.ImageURLs', []).map((image) => ({
                                ImageURL: image,
                            }))
                        );
                        isUpdate ? this.$emit('updated', obj) : this.$emit('created', obj);
                    })
                    .catch((error) => {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not create post. Please try again later.',
                            Details: null,
                        });
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                // error handling for image uploads
                const errorMsg = {
                    Message: 'Could not upload one or more images, please try again or report a bug.',
                    Details: error.response.data,
                };
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message === 'Request Entity Too Large'
                ) {
                    errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                }
                EventBus.$emit('ERROR', errorMsg);
                console.error(error);
                this.loading = false;
            }
        },
    },
};
