import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import EventBus from '@/eventBus';
import AdminSettingsSignUpStrategy from '@/views/admin/Settings/Authentications/AdminSettingsSignUpStrategy.vue';
import { get } from 'lodash';
import CoMyEvents from '@/components/Pages/user/event/co-my-events.vue';
import store from './store';

import Error404 from './views/Error404.vue';
import Suspended from './views/signup/Suspended.vue';

// Public
import MainPublic from '@/views/general/MainPublic.vue';
import CoViewPublicEvent from '@/components/Pages/user/event/CoViewPublicEvent.vue';

// Captive
import Captive from './views/captive/CaptiveNew.vue';
import CaptiveSuccess from './views/captive/CaptiveSuccess.vue';

// SignUp
import Login from './views/signup/Login.vue';
import Register from './views/signup/Register.vue';
import RegisterV2 from './views/signup/RegisterV2.vue';
import SignUpSuccess from './views/signup/Success.vue';
import EmailConfirmation from './views/signup/EmailConfirmation.vue';
import ResetPassword from './views/signup/ResetPassword.vue';
import ForceResetPassword from './views/signup/ForcePasswordReset.vue';
import SetNewPassword from './views/signup/SetNewPassword.vue';

// Plans
import Plans from './views/signup/Plans.vue';
import PlanDetails from './views/signup/PlanDetails.vue';

// Events
import ViewEventWrapper from './views/events/ViewEventWrapper.vue';
import CreateEventWrapper from './views/events/CreateEventWrapper.vue';
import Events from './views/events/Events.vue';
import EditEvent from './views/events/Edit.vue';
// Users
import ViewProfile from './views/users/ViewProfileV2.vue';
import Community from './views/users/Community.vue';

// routes for my profile
import MyBookings from './views/current_profile/MyBookings.vue';

// Projects
import Project from './views/projects/CreateProject.vue';
import ViewProject from './views/projects/ViewProject.vue';

// Jobs
import ViewJob from './views/jobs/ViewJob.vue';
import CreateJob from './views/jobs/CreateJob.vue';
import EditJob from './views/jobs/EditJob.vue';
import CoMarketItems from './components/Pages/user/market/co-market-items.vue';

// Chat
import NewMessage from './views/chat/NewMessage.vue';
import Messenger from './views/chat/Messenger.vue';
import Chat from './views/chat/Chat.vue';
import EditChat from './views/chat/EditChat.vue';

// Legal
import LegalBase from './views/legal/LegalBase.vue';
import PrivacyPolicy from './views/legal/PrivacyPolicy.vue';
import TermsAndConditions from './views/legal/TermsAndConditions.vue';

// Misc
import Notifications from './views/misc/Notifications.vue';
import Screen from './views/misc/Screen.vue';

// Booking
import BookingCalendar from './views/booking/BookingCalendar.vue';

// General
import Search from './views/general/Search.vue';
import Feed from './components/Pages/user/feed/co-feed.vue';
import SearchInApp from './views/general/SearchInApp.vue';
import UserMain from './components/Pages/user/UserMain.vue';
import Main from './views/general/Main.vue';
import Create from './views/general/Create.vue';

// Channels and Pages
import CoChannelList from './components/Pages/user/channel/co-channel-list.vue';
import CoMyPages from './components/Pages/user/channel/page/co-my-pages.vue';
import CoChannelPages from './components/Pages/user/channel/page/co-channel-pages.vue';

// Posts
import SinglePost from './components/Pages/user/feed/co-single-post.vue';

// Admin
import Admin from './views/admin/Admin.vue';

import AdminSpaceLocations from './views/admin/Space/Locations.vue';
import AdminSpaceAdministrators from './views/admin/Space/Administrators.vue';
import AdminSpaceWiFiAccess from './views/admin/Space/RaiusServer.vue';

import AdminSetupJavaScript from './views/admin/Setup/Javascript.vue';
import AdminSetupCustomization from './views/admin/Setup/Customization.vue';

import AdminSetupDomain from './views/admin/Setup/Domain.vue';

import AdminSettingsFeaturesBase from './views/admin/Settings/Features/Base.vue';
import AdminSettingsFeaturesBookings from './views/admin/Settings/Features/Bookings.vue';
import AdminSettingsFeaturesMarket from './views/admin/Settings/Features/Market.vue';
import AdminSettingsFeaturesMenu from './views/admin/Settings/Features/Menu.vue';

import CoAdminMembersPlan from './components/Pages/admin/members/co-admin-members-plan/CoAdminMembersPlan.vue';
import CoAdminMembersPlanCreate from './components/Pages/admin/members/co-admin-members-plan/CoAdminMembersPlanCreate.vue';
import CoAdminMembersBase from './components/Pages/admin/members/co-admin-members-base/CoAdminMembersBase.vue';
import CoAdminMembersPlanUpdate from './components/Pages/admin/members/co-admin-members-plan/CoAdminMembersPlanUpdate.vue';

import AdminMembersList from './views/admin/Community/Members/Members.vue';
import InvitedUsersList from './views/admin/Community/Members/InvitedUsers.vue';
import AdminMemberUpgrades from './views/admin/Community/Members/MemberUpgrades.vue';
import MemberAccountV2 from './views/admin/Community/Members/MemberAccountV2.vue';
import MemberAccountEdit from './views/admin/Community/Members/MemberAccountEdit.vue';
import MemberAccountTimePass from './views/admin/Community/Members/TimePass/MemberAccountTimePass.vue';
import MemberInvoices from './views/admin/Community/Members/MemberInvoices.vue';

import AdminBookings from './views/admin/Booking/Bookings.vue';
import AdminBookingCategories from './views/admin/Booking/BookingCategories.vue';
import AdminCreateBookingCategory from './views/admin/Booking/CreateBookingCategory.vue';
import AdminEditBookingCategory from './views/admin/Booking/EditBookingCategory.vue';
import AdminBookingStatistics from './views/admin/Booking/BookingStatistics.vue';

import AdminResources from './views/admin/Booking/BookinResources.vue';
import AdminCreateResource from './views/admin/Booking/ResourceCreate.vue';
import CoAdminCreateResource from './components/Pages/co-AdminCreateResource/CoAdminCreateResource.vue';
import AdminEditResource from './views/admin/Booking/ResourceEdit.vue';

import AdminEvents from './views/admin/Event/Events.vue';
import AdminEventEdit from './views/admin/Event/EventEdit.vue';
import AdminEventCreate from './views/admin/Event/EventCreate.vue';
import AdminEventTypes from './views/admin/Event/Event-types.vue';
import AdminEventCategories from './views/admin/Event/Event-categories.vue';

// Pages and Channels
import AdminPages from './views/admin/Pages/Pages.vue';
import AdminSettingsFeaturesChannels from './views/admin/Pages/Channels.vue';

import AdminAccount from './views/admin/Setup/Account.vue';

// Statistics
import AdminStatisticsBase from './views/admin/Statistics/Base.vue';
import AdminStatisticsGeneral from './views/admin/Statistics/Dashboard.vue';
import AdminStatisticsCheckins from './views/admin/Statistics/Checkins.vue';

// Monetization
import AdminMonetizationBase from './views/admin/Monetization/Base.vue';
import AdminMonetizationMenu from './views/admin/Monetization/Menu.vue';

// Access
import AdminAccessBase from './views/admin/Space/Access/Base.vue';
import AdminAccessMenu from './views/admin/Space/Access/Menu.vue';
import AdminAccessTagsBase from './views/admin/Space/Access/Tags/Base.vue';
import AdminAccessTagsList from './views/admin/Space/Access/Tags/List.vue';
import AdminAccessLocksBase from './views/admin/Space/Access/Locks/Base.vue';
import AdminAccessLocksList from './views/admin/Space/Access/Locks/List.vue';

import AdminAccessPoliciesBase from './views/admin/Space/Access/Policy/Base.vue';
import AdminAccessPoliciesList from './views/admin/Space/Access/Policy/List.vue';

import AdminAccessIdentityBase from './views/admin/Space/Access/Identity/Base.vue';
import AdminAccessIdentityList from './views/admin/Space/Access/Identity/List.vue';
import AdminAccessIdentityCreate from './views/admin/Space/Access/Identity/Create.vue';
import AdminAccessIdentityEdit from './views/admin/Space/Access/Identity/Edit.vue';

// Payment
import AdminPaymentsBase from './views/admin/Monetization/Payments/Base.vue';
import AdminPayments from './views/admin/Monetization/Payments/Payments.vue';
import AdminPaymentsStripe from './views/admin/Monetization/Payments/Stripe.vue';
import AdminPaymentsCurrency from './views/admin/Monetization/Payments/Currency.vue';

// PaymentMethods
import AdminPaymentMethods from './views/admin/Monetization/Payments/PaymentMethods.vue';

// Tax Rates
import AdminTaxRateBase from './views/admin/Monetization/Payments/TaxRate/Base.vue';
import AdminCreateTaxRate from './views/admin/Monetization/Payments/TaxRate/Create.vue';
import AdminListTaxRates from './views/admin/Monetization/Payments/TaxRate/List.vue';
import AdminEditTaxRate from './views/admin/Monetization/Payments/TaxRate/Edit.vue';

// Plans
import AdminPlanBase from './views/admin/Monetization/Plans/PlanBase.vue';
import AdminListPlans from './views/admin/Monetization/Plans/Plans.vue';
import AdminCreatePlan from './views/admin/Monetization/Plans/Create.vue';

import AdminEditPlan from './views/admin/Monetization/Plans/Edit.vue';
import AdminViewPlan from './views/admin/Monetization/Plans/View.vue';

// Plan upgrades
import AdminUpgradesBase from './views/admin/Monetization/PlanUpgrades/UpgradeBase.vue';
import AdminListPlanUpgrades from './views/admin/Monetization/PlanUpgrades/List.vue';
import AdminCreatePlanUpgrade from './views/admin/Monetization/PlanUpgrades/Create.vue';
import AdminEditPlanUpgrade from './views/admin/Monetization/PlanUpgrades/Edit.vue';
import AdminViewPlanUpgrade from './views/admin/Monetization/PlanUpgrades/View.vue';

// Circles
import AdminCircles from './views/admin/Circles/Circles.vue';

// Time Passes
import AdminTimepassesBase from './views/admin/Monetization/Timepasses/AdminTimepassesBase.vue';
import AdminListTimepasses from './views/admin/Monetization/Timepasses/List.vue';
import AdminViewTimepass from './views/admin/Monetization/Timepasses/View.vue';
import AdminEditTimepass from './views/admin/Monetization/Timepasses/Edit.vue';
import AdminCreateTimepass from './views/admin/Monetization/Timepasses/Create.vue';

// Settings
import AdminSettingsBase from './views/admin/Settings/Base.vue';
import AdminSettingsMenu from './views/admin/Settings/SettingsMenu.vue';

// Integrations
import AdminIntegrationsBase from './views/admin/Settings/Integrations/Base.vue';
import AdminIntegrations from './views/admin/Settings/Integrations/IntegrationsMenu.vue';
import AdminIntegrationsZapier from './views/admin/Settings/Integrations/Zapier.vue';
import AdminIntegrationsGoogleCalendar from './views/admin/Settings/Integrations/GoogleCalendar.vue';
import AdminIntegrationsNuki from './views/admin/Settings/Integrations/Nuki.vue';

// Permissions
import AdminSettingsPermissions from './components/Pages/admin/settings/co-permissions/CoPermissions.vue';

// Agreements
import AdminSettingsPrivacyPolicy from './views/admin/Settings/PrivacyPolicy.vue';
import AdminSettingsTermsAndConditions from './views/admin/Settings/TermsAndConditions.vue';

// Members settings
import AdminSettingsMemberSettings from './views/admin/Settings/CustomerSettings.vue';

// General
import AdminSettingsGeneral from './views/admin/Settings/General.vue';

// User Account
import Account from './views/account/Account.vue';
import GeneralAccountSettings from './views/account/GeneralAccountSettings.vue';
// import NotificationPreferences from './views/account/NotificationPreferences.vue';
import NotificationPreferences from './components/Pages/user/account/co-notifications-preferences.vue';
import ChangePlan from './views/account/plans/ChangePlan.vue';
import ChangePlanCheckout from './views/account/plans/ChangePlanCheckout.vue';
import CurrentPlanView from './views/account/plans/CurrentPlanView.vue';
import PlanViewDetails from './views/account/plans/PlanViewDetails.vue';
import TimepassOverview from './views/account/time-passes/Overview.vue';
import BuyPassDetails from './views/account/time-passes/BuyPassProcess.vue';
import Billing from './views/account/Billing.vue';
import PaymentMethods from './views/account/PaymentMethods.vue';
import Invoices from './views/account/Invoices.vue';
import NewPaymentMethod from './views/account/NewPaymentMethod.vue';

import AdminAuthenticationsBase from './views/admin/Settings/Authentications/Base.vue';
import AdminAuthentications from './views/admin/Settings/Authentications/Menu.vue';
import AdminAuthenticationsSignUp from './views/admin/Settings/Authentications/SignUp.vue';
import AdminAuthenticationsLogin from './views/admin/Settings/Authentications/Login.vue';
import AdminAuthenticationsProviders from './views/admin/Settings/Authentications/Providers.vue';
import AdminAuthenticationsProviderEdit from './views/admin/Settings/Authentications/ProviderEditCreate.vue';
import OAuth2Callback from './views/auth_methods/OAuth2Callback.vue';

Vue.use(Router);

/*
ifAuthenticated
router method returning the authentication status of user and redirecting to
login if valid session does not exist
*/
const ifAuthenticated = (to, from, next) => {
    const appToken = to.query.apptoken;
    if (appToken) {
        localStorage.setItem('_authtoken', appToken);
        axios.defaults.headers.common.Authorization = `Bearer ${appToken}`;
        store.state.isLoggedIn = true;

        if (to.query.m === 'mobile') {
            store.commit('SET_IS_IN_MOBILE', true);
        } else if (to.query.m === 'desktop') {
            store.commit('SET_IS_IN_MOBILE', false);
        }

        store.dispatch('getMe').then(() => {});

        next();
        return;
    }

    if (store.state.isLoggedIn) {
        next();
        return;
    }

    if (store.state.isLoggedIn) {
        next();
        return;
    }

    // if to contains /event/ then try to get the event as public
    // and show the event page if it exists and is public
    // otherwise redirect to login with the current path
    if (to.path.includes('/event/') || to.path.includes('/events/')) {
        const eventSlug = to.path.split('/').pop();
        axios({
            method: 'GET',
            url: `/public/event/${eventSlug}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.data && response.data.Slug) {
                    // if event is public, show the event page /public/event/:slug
                    next({
                        path: `/public/event/${eventSlug}`,
                    });
                } else {
                    next({
                        path: '/login',
                        query: { redirect: to.fullPath.replace('/public', '') },
                    });
                }
            })
            .catch(() => {
                next({
                    path: '/login',
                    query: { redirect: to.fullPath.replace('/public', '') },
                });
            });

        return;
    }

    if (to.fullPath === '/') {
        next({
            path: '/login',
            query: { redirect: '/' },
        });
    } else {
        next({
            path: '/login',
            query: { redirect: to.fullPath },
        });
    }
};

const ifTeamMember = (to, from, next) => {
    if (store.state.isLoggedIn) {
        if (store.state.me.Permissions && store.state.me.Permissions.includes('space_admin')) {
            next();
        } else {
            next('/');
        }
    } else {
        next({
            path: '/login',
            query: { redirect: to.fullPath },
        });
    }
};

const logPageView = (routerObj) => {
    if (!routerObj || typeof routerObj !== 'object') {
        // missing or wrong type of routerObj
        return;
    }

    let data;
    data = {
        full_path: routerObj.fullPath,
        parameters: {
            ...get(routerObj, 'query', {}),
            apptype: get(store, 'state.isInMobile', false) ? 'mobile-app' : 'web-app',
            language: get(window, 'navigator.language', 'unknown'),
            platform: get(window, 'navigator.platform', 'unknown'),
            useragent: get(window, 'navigator.userAgent', 'unknown'),
        },
    };
    data = JSON.stringify(data);

    axios({
        method: 'POST',
        url: '/me/page-visit',
        data,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            // console.log("Page view logging was successfull", routerObj);
        })
        .catch((error) => {
            // console.log("Page view logging failed");
        });
};

function hasQueryParams(route) {
    return !!Object.keys(route.query).length;
}

const redirectorToMyProfile = (to, from, next) => {
    next('/profile/me');
};

const redirectorTo404 = (to, from, next) => {
    next('/error404');
};

const checkIfAllowedToSingup = (to, from, next) => {
    const inviteId = to.query.invite;
    if (inviteId) {
        store.commit('SET_INVITE_ID', inviteId);
    } else {
        store.commit('SET_INVITE_ID', null);
        store.commit('SET_INVITE', null);
    }

    // convert "to" to URL string
    const toStr = to.fullPath;

    // get signup settings
    // if space is invite-only, check if the invite is valid and allow signup only if it is valid
    // if space is not invite-only, allow signup directly
    store
        .dispatch('getSignupSettings')
        .then((response) => {
            const signupSettings = response.data;
            if (signupSettings.signUpStrategy === 'invite-only') {
                // wait for the invite to be validated before allowing signup
                store
                    .dispatch('validateSignupInvite', inviteId)
                    .then(() => {
                        // checkif billing is turned on
                        axios({
                            method: 'GET',
                            url: '/user/account/billing/payment-state',
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                            .then((response2) => {
                                if (response2 && response2.data && response2.data.ChargesEnabled) {
                                    next({ path: '/plans', query: { invite: inviteId } });
                                } else {
                                    // allow signup directly if billing is turned off and space is not invite-only
                                    next();
                                }
                            })
                            .catch((error) => {
                                // allow signup directly if billing is turned off and space is not invite-only
                                EventBus.$emit('ERROR', {
                                    Message:
                                        'Failed to sign up settings. Please try again later. If the problem persists, please contact support. E001',
                                });

                                next({
                                    path: '/login',
                                });
                            });
                    })
                    .catch(() => {
                        // if invite is not valid, redirect to login page
                        EventBus.$emit('ERROR', {
                            Message: 'The invite link is invalid.',
                        });

                        next({
                            path: '/login',
                            query: { invalidInviteLink: toStr },
                        });
                    });
            } else {
                // checkif billing is turned on
                axios({
                    method: 'GET',
                    url: '/user/account/billing/payment-state',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response2) => {
                        if (response2 && response2.data && response2.data.ChargesEnabled) {
                            next({ path: '/plans', query: { invite: inviteId } });
                        } else {
                            // allow signup directly if billing is turned off and space is not invite-only
                            next();
                        }
                    })
                    .catch((error) => {
                        EventBus.$emit('ERROR', {
                            Message:
                                'Failed to sign up settings. Please try again later. If the problem persists, please contact support. E002',
                        });

                        next({
                            path: '/login',
                        });
                    });
            }
        })
        .catch((error) => {
            console.log(error);
            // if error is 404, allow signup because it means that the space doesn't have signup settings yet, default to allowing signup
            if (error.response.status === 404) {
                // checkif billing is turned on
                axios({
                    method: 'GET',
                    url: '/user/account/billing/payment-state',
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response2) => {
                        if (response2 && response2.data && response2.data.ChargesEnabled) {
                            next({ path: '/plans', query: { invite: inviteId } });
                        } else {
                            // allow signup directly if billing is turned off and space is not invite-only
                            next();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        EventBus.$emit('ERROR', {
                            Message:
                                'Failed to sign up settings. Please try again later. If the problem persists, please contact support. E003',
                        });

                        next({
                            path: '/login',
                        });
                    });
            } else {
                // if error is not 404, redirect to login page because we don't know if signup is allowed or not yet
                // this is to prevent users from being able to signup when signup is not allowed
                EventBus.$emit('ERROR', {
                    Message:
                        'Failed to get signup settings. Please try again later. If the problem persists, please contact support. E004',
                });
                next('/login');
            }
        });
};

// checkIfInviteIsValidForPaid used to verify if invit in query or vues is valid
const checkIfInviteIsValidForPaid = (to, from, next) => {
    const inviteId = store.state.newMembership.InviteID || to.query.invite;

    if (inviteId) {
        store.commit('SET_INVITE_ID', inviteId);
    } else {
        store.commit('SET_INVITE_ID', null);
        store.commit('SET_INVITE', null);
    }

    // get signup settings
    // if space is invite-only, check if the invite is valid and allow signup only if it is valid
    // if space is not invite-only, allow signup directly
    store
        .dispatch('getSignupSettings')
        .then((response) => {
            const signupSettings = response.data;
            if (signupSettings.signUpStrategy === 'invite-only') {
                // wait for the invite to be validated before allowing signup
                store
                    .dispatch('validateSignupInvite', inviteId)
                    .then(() => {
                        next();
                    })
                    .catch(() => {
                        // if invite is not valid, redirect to login page
                        EventBus.$emit('ERROR', {
                            Message:
                                'The invite link is invalid. Please contact the space admin for a new invite link.',
                        });
                        next('/login');
                    });
            } else {
                next();
            }
        })
        .catch((error) => {
            console.log(error);
            // if error is not 404, redirect to login page because we don't know if signup is allowed or not yet
            // this is to prevent users from being able to signup when signup is not allowed
            EventBus.$emit('ERROR', {
                Message:
                    'Failed to get signup settings. Please try again later. If the problem persists, please contact support. E005',
            });
            next('/login');
        });
};

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/feed',
            redirect: '/',
        },
        {
            meta: {
                'og:image': store.state.space.LogoURL,
                Title: `${store.state.space.Name} Community Platform`,
                requiresAuth: true,
            },
            path: '/',
            component: Main,
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: '/feed',
                    redirect: '/',
                },
                {
                    path: '',
                    component: UserMain,
                    children: [
                        {
                            name: 'Timeline',
                            path: '',
                            component: Feed,
                        },
                        {
                            // catch all 404 routing
                            // redirect all unresolved urls to dashboard
                            path: 'error404',
                            name: 'Error 404',
                            component: Error404,
                        },
                        {
                            path: '/submit-job',
                            redirect: '/submit-market-item',
                        },

                        {
                            name: 'CreateMarketItem',
                            path: '/submit-market-item',
                            component: CreateJob,
                        },

                        {
                            name: 'Events',
                            path: '/events',
                            component: Events,
                        },
                        {
                            name: 'Create event',
                            path: '/events/create',
                            component: CreateEventWrapper,
                        },
                        {
                            path: '/events/:slug',
                            alias: '/event/:slug',
                            name: 'View Event',
                            component: ViewEventWrapper,
                            meta: {
                                mainGridOptions: {
                                    hideRight: true,
                                    stretchCenter: true,
                                },
                            },
                        },
                        {
                            name: 'Market',
                            path: '/market',
                            alias: '/jobs',
                            component: CoMarketItems,
                        },
                        {
                            name: 'Community',
                            path: '/community',
                            component: Community,
                        },
                        {
                            name: 'ChannelPages',
                            path: '/channels/:slug',
                            component: CoChannelPages,
                        },
                        {
                            name: 'CoMyPages',
                            path: '/profile/my-pages',
                            component: CoMyPages,
                        },
                        {
                            name: 'MyEvents',
                            path: '/profile/my-events',
                            component: CoMyEvents,
                        },
                        {
                            path: '/messenger',
                            component: Messenger,
                            children: [
                                {
                                    name: 'EditChat',
                                    path: 'edit/:slug',
                                    component: EditChat,
                                },
                                {
                                    name: 'Chat',
                                    path: ':slug',
                                    component: Chat,
                                },
                                {
                                    name: 'NewMessage',
                                    path: '',
                                    component: NewMessage,
                                },
                            ],
                        },
                        {
                            name: 'Single Post View',
                            path: '/post/:slug',
                            component: SinglePost,
                        },
                        {
                            name: 'Single Page Update View',
                            path: '/project-update/:slug',
                            component: SinglePost,
                        },
                        {
                            name: 'Event as Post View',
                            path: '/event-as-post/:slug',
                            component: SinglePost,
                        },
                        {
                            name: 'Page as Post View',
                            path: '/page-as-post/:slug',
                            component: SinglePost,
                        },
                        {
                            name: 'Market Item as Post View',
                            path: '/market-item-as-post/:slug',
                            component: SinglePost,
                        },
                        {
                            name: 'DetailView Job',
                            path: '/jobs/:slug',
                            component: SinglePost,
                        },
                        {
                            path: '/post/edit/:slug',
                            redirect: '/post/:slug',
                        },
                        {
                            path: '/notifications',
                            name: 'Notifications',
                            component: Notifications,
                        },
                    ],
                },
                {
                    path: '/settings',
                    redirect: '/account',
                },
                {
                    path: '/settings/notifications',
                    redirect: '/account/notifications',
                },
                {
                    path: '/account',
                    name: 'Account',
                    component: Account,
                    children: [
                        {
                            path: 'notifications',
                            name: 'NotificationPreferences',
                            component: NotificationPreferences,
                        },
                        {
                            path: '',
                            name: 'GeneralAccountSettings',
                            component: GeneralAccountSettings,
                        },
                        {
                            path: 'plan/view/:ID',
                            name: 'PlanViewDetails',
                            component: PlanViewDetails,
                        },
                        {
                            path: 'plan/change/:ID',
                            name: 'ChangePlanCheckout',
                            component: ChangePlanCheckout,
                        },
                        {
                            path: 'plan/change',
                            name: 'ChangePlan',
                            component: ChangePlan,
                        },
                        {
                            path: 'plan',
                            name: 'CurrentUserPlanView',
                            component: CurrentPlanView,
                        },

                        {
                            path: 'billing/add-payment-method',
                            name: 'Add payment method',
                            component: NewPaymentMethod,
                        },
                        {
                            path: 'billing',
                            name: 'Billing',
                            component: Billing,
                        },

                        {
                            path: 'billing/invoices',
                            name: 'Invoices',
                            component: Invoices,
                        },
                        {
                            path: 'billing/payment-methods',
                            name: 'Payment Methods',
                            component: PaymentMethods,
                        },
                        {
                            path: 'time-passes',
                            name: 'TimepassOverview',
                            component: TimepassOverview,
                        },
                        {
                            path: 'time-passes/buy/:ID',
                            name: 'BuyPassDetails',
                            component: BuyPassDetails,
                        },
                        {
                            path: '*',
                            redirect: '/account',
                        },
                    ],
                },
                {
                    path: '/search',
                    component: UserMain,
                    // beforeEnter: ifAuthenticated,
                    children: [
                        {
                            path: '',
                            name: 'search',
                            component: Search,
                        },
                        {
                            path: 'in-app',
                            name: 'search-in-app',
                            component: SearchInApp,
                        },
                    ],
                },
                {
                    // Create will be rendered inside User's <router-view>
                    // when /create is matched
                    path: '/create',
                    component: Create,
                    children: [
                        {
                            path: 'Page',
                            component: Project,
                        },
                    ],
                },
                {
                    name: 'Booking Calendar',
                    path: '/booking-calendar',
                    component: BookingCalendar,
                },

                {
                    path: '/profile/my-bookings',
                    name: 'MyBookings',
                    component: MyBookings,
                },
                {
                    path: '/myprofile',
                    name: 'MyProfile',
                    beforeEnter: redirectorToMyProfile,
                },
                {
                    path: '/profile/:slug',
                    name: 'Profile',
                    component: ViewProfile,
                },
                {
                    name: 'ChannelPage',
                    path: '/channels/:channelsSlug/:slug',
                    component: ViewProject,
                },
                {
                    name: 'ChannelList',
                    path: '/channels',
                    component: CoChannelList,
                },
                {
                    path: '/project/:slug',
                    name: 'View Project',
                    component: ViewProject,
                },
                {
                    path: '/job/:slug',
                    name: 'View Job',
                    component: ViewJob,
                },
                {
                    path: '/job/edit/:slug',
                    name: 'EditJob',
                    component: EditJob,
                },
                {
                    path: '/event/edit/:slug',
                    name: 'Edit Event',
                    component: EditEvent,
                },
            ],
        },

        {
            path: '/admin',
            component: Admin,
            beforeEnter: ifTeamMember,
            children: [
                {
                    name: 'Space Administrators',
                    path: 'space/administrators',
                    component: AdminSpaceAdministrators,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Administrators',
                            },
                        ],
                    },
                },
                {
                    name: 'WiFi Access',
                    path: 'space/wifi-access',
                    component: AdminSpaceWiFiAccess,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Space',
                                to: '/admin/space/wifi-access',
                            },
                            {
                                text: 'WiFi Access',
                            },
                        ],
                    },
                },
                {
                    name: 'Space Locations',
                    path: 'space/locations',
                    component: AdminSpaceLocations,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Space',
                                to: '/admin/space/locations',
                            },
                            {
                                text: 'Locations',
                            },
                        ],
                    },
                },
                {
                    name: 'AdminAccount',
                    path: 'account',
                    component: AdminAccount,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Settings',
                                to: '/admin/settings',
                            },
                            {
                                text: 'Account',
                            },
                        ],
                    },
                },
                {
                    name: 'JavaScript',
                    path: 'setup/javascript',
                    component: AdminSetupJavaScript,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Settings',
                                to: '/admin/settings',
                            },
                            {
                                text: 'JavaScript',
                            },
                        ],
                    },
                },
                {
                    path: 'setup/colors',
                    redirect: 'setup/customization',
                },

                {
                    path: 'setup/intro',
                    redirect: 'setup/customization',
                },
                {
                    path: 'setup/logo',
                    redirect: 'setup/customization',
                },
                {
                    name: 'customization',
                    path: 'setup/customization',
                    component: AdminSetupCustomization,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Settings',
                                to: '/admin/settings',
                            },
                            {
                                text: 'Customization',
                            },
                        ],
                    },
                },
                {
                    name: 'signup-strategy',
                    path: 'setup/signup-strategy',
                    component: AdminSettingsSignUpStrategy,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Settings',
                                to: '/admin/settings',
                            },
                            {
                                text: 'Authentication',
                                to: '/admin/settings/authentications',
                            },
                            {
                                text: 'Signup Strategy',
                            },
                        ],
                    },
                },
                {
                    name: 'Domain',
                    path: 'setup/domain',
                    component: AdminSetupDomain,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Settings',
                                to: '/admin/settings',
                            },
                            {
                                text: 'Domain',
                            },
                        ],
                    },
                },

                /**
                 * New Members routes
                 */
                {
                    path: 'member/:ID',
                    component: CoAdminMembersBase,
                    children: [
                        {
                            name: 'AdminMemberPlanUpdate',
                            path: 'plan/update',
                            component: CoAdminMembersPlanUpdate,
                        },
                        {
                            name: 'AdminMemberPlanCreate',
                            path: 'plan/create',
                            component: CoAdminMembersPlanCreate,
                        },
                        {
                            name: 'AdminMemberPlan',
                            path: 'plan',
                            component: CoAdminMembersPlan,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Members',
                                        to: '/admin/community/member/list',
                                    },
                                    {
                                        text: '{{username}}', // will be replaced with username from CoAdminMembersBase page
                                        to: '{{profile_url}}', // will be replaced with profile_url from CoAdminMembersBase page
                                    },
                                    {
                                        text: 'Plan',
                                    },
                                ],
                            },
                        },
                    ],
                },

                /**
                 * Community Members routes
                 */
                {
                    name: 'Community Members',
                    path: 'community/member/list',
                    component: AdminMembersList,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Members',
                            },
                        ],
                    },
                },
                {
                    name: 'Community Member Upgrade List',
                    path: 'community/member/upgrades',
                    component: AdminMemberUpgrades,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Members',
                                to: '/admin/community/member/list',
                            },
                            {
                                text: 'Upgrades',
                            },
                        ],
                    },
                },
                {
                    name: 'Invited Users',
                    path: 'community/member/invitations',
                    component: InvitedUsersList,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Invited',
                            },
                        ],
                    },
                },
                {
                    name: 'Community Member Account',
                    path: 'community/member/view/:ID',
                    component: MemberAccountV2,
                },
                {
                    name: 'Community Member Invoices',
                    path: 'community/member/invoices/:ID',
                    component: MemberInvoices,
                },
                {
                    name: 'Community Member Account Edit',
                    path: 'community/member/edit/:ID',
                    component: MemberAccountEdit,
                },
                {
                    name: 'Community Member Account Time Pass',
                    path: 'community/member/time-passes/:ID',
                    component: MemberAccountTimePass,
                },
                {
                    name: 'Community Member Account Purchase Time Pass',
                    path: 'community/member/time-passes/purchase/:ID',
                    component: MemberAccountTimePass,
                },
                /**
                 * Booking routes
                 */
                {
                    name: 'Booking calendar',
                    path: 'booking/calendar',
                    component: AdminBookings,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Booking calendar',
                            },
                        ],
                    },
                },
                {
                    name: 'Booking categories',
                    path: 'booking/categories',
                    component: AdminBookingCategories,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Booking categories',
                            },
                        ],
                    },
                },
                {
                    name: 'Create booking category',
                    path: 'booking/categories/create',
                    component: AdminCreateBookingCategory,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Booking categories',
                                to: '/admin/booking/categories',
                            },
                            {
                                text: 'Create',
                            },
                        ],
                    },
                },
                {
                    name: 'Edit booking category',
                    path: 'booking/categories/edit/:id',
                    component: AdminEditBookingCategory,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Booking categories',
                                to: '/admin/booking/categories',
                            },
                            {
                                text: 'Edit',
                            },
                        ],
                    },
                },
                {
                    name: 'Booking Statistics',
                    path: 'booking/statistics',
                    component: AdminBookingStatistics,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Bookings',
                                to: '/admin/booking/calendar',
                            },
                            {
                                text: 'Statistics',
                                to: '/admin/booking/statistics',
                            },
                        ],
                    },
                },
                {
                    name: 'Booking resources',
                    path: 'booking/resource',
                    component: AdminResources,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Booking resources',
                            },
                        ],
                    },
                },
                {
                    name: 'Create booking resource',
                    path: 'booking/resource/create',
                    component: AdminCreateResource,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Booking resources',
                                to: '/admin/booking/resource',
                            },
                            {
                                text: 'Create',
                            },
                        ],
                    },
                },
                {
                    name: 'Edit booking resource',
                    path: 'booking/resource/edit/:id',
                    component: AdminEditResource,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Booking resources',
                                to: '/admin/booking/resource',
                            },
                            {
                                text: 'Edit',
                            },
                        ],
                    },
                },
                {
                    name: 'Admin events',
                    path: 'event/list',
                    component: AdminEvents,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Events',
                            },
                        ],
                    },
                },
                {
                    name: 'Admin create event',
                    path: 'event/create',
                    component: AdminEventCreate,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Events',
                                to: '/admin/event/list',
                            },
                            {
                                text: 'Create',
                            },
                        ],
                    },
                },
                {
                    name: 'Edit event',
                    path: 'event/edit/:slug',
                    component: AdminEventEdit,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Events',
                                to: '/admin/event/list',
                            },
                            {
                                text: 'Edit',
                            },
                        ],
                    },
                },
                {
                    name: 'Event categories',
                    path: 'event/categories',
                    component: AdminEventCategories,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Events categories',
                            },
                        ],
                    },
                },
                {
                    name: 'Event types',
                    path: 'event/types',
                    component: AdminEventTypes,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Events types',
                            },
                        ],
                    },
                },
                {
                    name: 'Pages List',
                    path: 'pages/list',
                    component: AdminPages,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Channels & Pages',
                                to: '/admin/pages/list',
                            },
                            {
                                text: 'Pages',
                            },
                        ],
                    },
                },
                {
                    name: 'Channels',
                    path: 'pages/channels',
                    component: AdminSettingsFeaturesChannels,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Channels & Pages',
                                to: '/admin/pages/list',
                            },
                            {
                                text: 'Channels',
                            },
                        ],
                    },
                },

                /**
                 * Statistics routes
                 */
                {
                    name: 'Dashboard',
                    path: 'dashboard',
                    component: AdminStatisticsBase,
                    redirect: 'dashboard/general',
                    children: [
                        {
                            path: 'general',
                            component: AdminStatisticsGeneral,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Statistics',
                                        to: '/admin/dashboard/general',
                                    },
                                    {
                                        text: 'General',
                                    },
                                ],
                            },
                        },
                        {
                            path: 'check-ins',
                            component: AdminStatisticsCheckins,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Statistics',
                                        to: '/admin/dashboard/check-ins',
                                    },
                                    {
                                        text: 'Check-ins',
                                    },
                                ],
                            },
                        },
                    ],
                },

                // Access
                {
                    path: 'access',
                    component: AdminAccessBase,
                    children: [
                        {
                            name: 'Access',
                            path: '',
                            component: AdminAccessMenu,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Access',
                                    },
                                ],
                            },
                        },
                        {
                            path: 'tag',
                            component: AdminAccessTagsBase,
                            children: [
                                {
                                    name: 'Tags',
                                    path: '',
                                    component: AdminAccessTagsList,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Access',
                                                to: '/admin/access',
                                            },
                                            {
                                                text: 'Tags',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                        {
                            path: 'lock',
                            component: AdminAccessLocksBase,
                            children: [
                                {
                                    name: 'Locks',
                                    path: '',
                                    component: AdminAccessLocksList,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Access',
                                                to: '/admin/access',
                                            },
                                            {
                                                text: 'Locks',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                        {
                            path: 'policy',
                            component: AdminAccessPoliciesBase,
                            children: [
                                {
                                    name: 'Policies',
                                    path: '',
                                    component: AdminAccessPoliciesList,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Access',
                                                to: '/admin/access',
                                            },
                                            {
                                                text: 'Policies',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                        {
                            path: 'identity',
                            component: AdminAccessIdentityBase,
                            children: [
                                {
                                    name: 'Identities',
                                    path: '',
                                    component: AdminAccessIdentityList,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Access',
                                                to: '/admin/access',
                                            },
                                            {
                                                text: 'Identities',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'Create identity',
                                    path: 'create',
                                    component: AdminAccessIdentityCreate,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Access',
                                                to: '/admin/access',
                                            },
                                            {
                                                text: 'Identities',
                                                to: '/admin/access/identity',
                                            },
                                            {
                                                text: 'Create',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'Edit identity',
                                    path: 'edit/:id',
                                    component: AdminAccessIdentityEdit,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Access',
                                                to: '/admin/access',
                                            },
                                            {
                                                text: 'Identities',
                                                to: '/admin/access/identity',
                                            },
                                            {
                                                text: 'Edit',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    ],
                },
                // Admin settings
                {
                    path: 'settings',
                    component: AdminSettingsBase,

                    children: [
                        {
                            name: 'SettingsMenu',
                            path: '/',
                            component: AdminSettingsMenu,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Settings',
                                        to: '/admin/settings',
                                    },
                                ],
                            },
                        },
                        {
                            name: 'Permissions',
                            path: 'permissions',
                            component: AdminSettingsPermissions,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Settings',
                                        to: '/admin/settings',
                                    },
                                    {
                                        text: 'Permissions',
                                    },
                                ],
                            },
                        },
                        {
                            name: 'Features',
                            path: 'features',
                            component: AdminSettingsFeaturesBase,
                            children: [
                                {
                                    name: 'Bookings',
                                    path: 'bookings',
                                    component: AdminSettingsFeaturesBookings,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Features',
                                                to: '/admin/settings/features',
                                            },
                                            {
                                                text: 'Bookings',
                                            },
                                        ],
                                    },
                                },
                                {
                                    // deprecates path, was moved to "Channels & Pages" section
                                    name: 'Channels',
                                    path: 'channels',
                                    component: AdminSettingsFeaturesChannels,
                                    redirect: '/admin/pages/channels',
                                },
                                {
                                    name: 'Market',
                                    path: 'market',
                                    component: AdminSettingsFeaturesMarket,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Features',
                                                to: '/admin/settings/features',
                                            },
                                            {
                                                text: 'Market',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'FeaturesMenu',
                                    path: '',
                                    component: AdminSettingsFeaturesMenu,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Features',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                        {
                            path: 'integrations',
                            component: AdminIntegrationsBase,
                            children: [
                                {
                                    name: 'Zapier',
                                    path: 'zapier',
                                    component: AdminIntegrationsZapier,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Integrations',
                                                to: '/admin/settings/integrations',
                                            },
                                            {
                                                text: 'Zapier',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'Google Calendar',
                                    path: 'google-calendar',
                                    component: AdminIntegrationsGoogleCalendar,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Integrations',
                                                to: '/admin/settings/integrations',
                                            },
                                            {
                                                text: 'Google Calendar',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'Nuki',
                                    path: 'nuki',
                                    component: AdminIntegrationsNuki,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Integrations',
                                                to: '/admin/settings/integrations',
                                            },
                                            {
                                                text: 'Nuki',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'Integrations',
                                    path: '',
                                    component: AdminIntegrations,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Integrations',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },

                        {
                            name: 'General settings',
                            path: 'general',
                            component: AdminSettingsGeneral,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Settings',
                                        to: '/admin/settings',
                                    },
                                    {
                                        text: 'General',
                                    },
                                ],
                            },
                        },
                        {
                            name: 'PrivacyPolicy',
                            path: 'privacy-policy',
                            component: AdminSettingsPrivacyPolicy,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Settings',
                                        to: '/admin/settings',
                                    },
                                    {
                                        text: 'Privacy Policy',
                                    },
                                ],
                            },
                        },
                        {
                            name: 'TermsAndConditions',
                            path: 'terms-and-conditions',
                            component: AdminSettingsTermsAndConditions,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Settings',
                                        to: '/admin/settings',
                                    },
                                    {
                                        text: 'Terms and Conditions',
                                    },
                                ],
                            },
                        },
                        {
                            name: 'Customer settings',
                            path: 'customer-number-serttings',
                            component: AdminSettingsMemberSettings,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Settings',
                                        to: '/admin/settings',
                                    },
                                    {
                                        text: 'Customer number settings',
                                    },
                                ],
                            },
                        },
                        {
                            path: 'authentications',
                            component: AdminAuthenticationsBase,
                            children: [
                                {
                                    name: 'AuthenticationsMenu',
                                    path: '',
                                    component: AdminAuthentications,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Authentication',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'AuthenticationsSignUp',
                                    path: 'sign-up',
                                    component: AdminAuthenticationsSignUp,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Authentication',
                                                to: '/admin/settings/authentications',
                                            },
                                            {
                                                text: 'Sign Up',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'AuthenticationsLogin',
                                    path: 'login',
                                    component: AdminAuthenticationsLogin,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Authentication',
                                                to: '/admin/settings/authentications',
                                            },
                                            {
                                                text: 'Login',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'AuthenticationsIdentityProviders',
                                    path: 'providers',
                                    component: AdminAuthenticationsBase,
                                    children: [
                                        {
                                            path: '',
                                            name: 'AuthenticationsProvidersMenu',
                                            component: AdminAuthenticationsProviders,
                                            meta: {
                                                breadcrumbs: [
                                                    {
                                                        text: 'Manage',
                                                        to: '/admin',
                                                    },
                                                    {
                                                        text: 'Settings',
                                                        to: '/admin/settings',
                                                    },
                                                    {
                                                        text: 'Authentication',
                                                        to: '/admin/settings/authentications',
                                                    },
                                                    {
                                                        text: 'Identity Providers',
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            name: 'AuthenticationsProviderCreate',
                                            path: 'create',
                                            component: AdminAuthenticationsProviderEdit,
                                            meta: {
                                                breadcrumbs: [
                                                    {
                                                        text: 'Manage',
                                                        to: '/admin',
                                                    },
                                                    {
                                                        text: 'Settings',
                                                        to: '/admin/settings',
                                                    },
                                                    {
                                                        text: 'Authentication',
                                                        to: '/admin/settings/authentications',
                                                    },
                                                    {
                                                        text: 'Identity Providers',
                                                        to: '/admin/settings/authentications/providers',
                                                    },
                                                    {
                                                        text: 'Create',
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            name: 'AuthenticationsProviderEdit',
                                            path: ':authMethodID',
                                            component: AdminAuthenticationsProviderEdit,
                                            meta: {
                                                breadcrumbs: [
                                                    {
                                                        text: 'Manage',
                                                        to: '/admin',
                                                    },
                                                    {
                                                        text: 'Settings',
                                                        to: '/admin/settings',
                                                    },
                                                    {
                                                        text: 'Authentication',
                                                        to: '/admin/settings/authentications',
                                                    },
                                                    {
                                                        text: 'Identity Providers',
                                                        to: '/admin/settings/authentications/providers',
                                                    },
                                                    {
                                                        text: 'Edit',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                // Monetization settings
                {
                    path: 'monetization',
                    component: AdminMonetizationBase,
                    children: [
                        {
                            name: 'MonetizationMenu',
                            path: '/',
                            component: AdminMonetizationMenu,
                            meta: {
                                breadcrumbs: [
                                    {
                                        text: 'Manage',
                                        to: '/admin',
                                    },
                                    {
                                        text: 'Settings',
                                        to: '/admin/settings',
                                    },
                                    {
                                        text: 'Monetization',
                                    },
                                ],
                            },
                        },
                        {
                            path: 'payments',
                            component: AdminPaymentsBase,
                            children: [
                                {
                                    name: 'Payments',
                                    path: '',
                                    component: AdminPayments,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Payments',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'Stripe',
                                    path: 'stripe',
                                    component: AdminPaymentsStripe,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Payments',
                                                to: '/admin/monetization/payments',
                                            },
                                            {
                                                text: 'Stripe',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'PaymentMethods',
                                    path: 'payment-methods',
                                    component: AdminPaymentMethods,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Payments',
                                                to: '/admin/monetization/payments',
                                            },
                                            {
                                                text: 'Payment Methods',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'Currency',
                                    path: 'currency',
                                    component: AdminPaymentsCurrency,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Payments',
                                                to: '/admin/monetization/payments',
                                            },
                                            {
                                                text: 'Currency',
                                            },
                                        ],
                                    },
                                },
                                {
                                    path: 'tax-rate',
                                    component: AdminTaxRateBase,
                                    children: [
                                        {
                                            name: 'CreateTaxRate',
                                            path: 'create',
                                            component: AdminCreateTaxRate,
                                            meta: {
                                                breadcrumbs: [
                                                    {
                                                        text: 'Manage',
                                                        to: '/admin',
                                                    },
                                                    {
                                                        text: 'Settings',
                                                        to: '/admin/settings',
                                                    },
                                                    {
                                                        text: 'Monetization',
                                                        to: '/admin/monetization',
                                                    },
                                                    {
                                                        text: 'Payments',
                                                        to: '/admin/monetization/payments',
                                                    },
                                                    {
                                                        text: 'Tax Rates',
                                                        to: '/admin/monetization/payments/tax-rate',
                                                    },
                                                    {
                                                        text: 'Create',
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            name: 'EditTaxRate',
                                            path: ':ID',
                                            component: AdminEditTaxRate,
                                            meta: {
                                                breadcrumbs: [
                                                    {
                                                        text: 'Manage',
                                                        to: '/admin',
                                                    },
                                                    {
                                                        text: 'Settings',
                                                        to: '/admin/settings',
                                                    },
                                                    {
                                                        text: 'Monetization',
                                                        to: '/admin/monetization',
                                                    },
                                                    {
                                                        text: 'Payments',
                                                        to: '/admin/monetization/payments',
                                                    },
                                                    {
                                                        text: 'Tax Rates',
                                                        to: '/admin/monetization/payments/tax-rate',
                                                    },
                                                    {
                                                        text: 'Edit',
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            name: 'ListTaxRates',
                                            path: '',
                                            component: AdminListTaxRates,
                                            meta: {
                                                breadcrumbs: [
                                                    {
                                                        text: 'Manage',
                                                        to: '/admin',
                                                    },
                                                    {
                                                        text: 'Settings',
                                                        to: '/admin/settings',
                                                    },
                                                    {
                                                        text: 'Monetization',
                                                        to: '/admin/monetization',
                                                    },
                                                    {
                                                        text: 'Payments',
                                                        to: '/admin/monetization/payments',
                                                    },
                                                    {
                                                        text: 'Tax Rates',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'plan',
                            component: AdminPlanBase,
                            children: [
                                {
                                    name: 'CreatePlan',
                                    path: 'create',
                                    component: AdminCreatePlan,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plans',
                                                to: '/admin/monetization/plan',
                                            },
                                            {
                                                text: 'Create',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'EditPlan',
                                    path: 'edit/:ID',
                                    component: AdminEditPlan,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plans',
                                                to: '/admin/monetization/plan',
                                            },
                                            {
                                                text: 'Edit',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'ViewPlan',
                                    path: ':ID',
                                    component: AdminViewPlan,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plans',
                                                to: '/admin/monetization/plan',
                                            },
                                            {
                                                text: 'View',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'ListPlans',
                                    path: '',
                                    component: AdminListPlans,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plans',
                                                to: '/admin/monetization/plan',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                        {
                            path: 'plan-upgrade',
                            component: AdminUpgradesBase,
                            children: [
                                {
                                    name: 'CreatePlanUpgrade',
                                    path: 'create',
                                    component: AdminCreatePlanUpgrade,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plan Upgrades',
                                                to: '/admin/monetization/plan-upgrade',
                                            },
                                            {
                                                text: 'Create',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'EditPlanUpgrade',
                                    path: 'edit/:ID',
                                    component: AdminEditPlanUpgrade,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plan Upgrades',
                                                to: '/admin/monetization/plan-upgrade',
                                            },
                                            {
                                                text: 'Edit',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'ViewPlanUpgrade',
                                    path: ':ID',
                                    component: AdminViewPlanUpgrade,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plan Upgrades',
                                                to: '/admin/monetization/plan-upgrade',
                                            },
                                            {
                                                text: 'View',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'ListPlanUpgrades',
                                    path: '',
                                    component: AdminListPlanUpgrades,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Plan Upgrades',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                        {
                            path: 'time-passes',
                            component: AdminTimepassesBase,
                            children: [
                                {
                                    name: 'Timepasses',
                                    path: '',
                                    component: AdminListTimepasses,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Timepasses',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'AdminViewTimepass',
                                    path: 'view/:ID',
                                    component: AdminViewTimepass,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Timepasses',
                                                to: '/admin/monetization/time-passes',
                                            },
                                            {
                                                text: 'View',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'AdminCreateTimepass',
                                    path: 'create',
                                    component: AdminCreateTimepass,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Timepasses',
                                                to: '/admin/monetization/time-passes',
                                            },
                                            {
                                                text: 'Create',
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'AdminEditTimepass',
                                    path: 'edit/:ID',
                                    component: AdminEditTimepass,
                                    meta: {
                                        breadcrumbs: [
                                            {
                                                text: 'Manage',
                                                to: '/admin',
                                            },
                                            {
                                                text: 'Settings',
                                                to: '/admin/settings',
                                            },
                                            {
                                                text: 'Monetization',
                                                to: '/admin/monetization',
                                            },
                                            {
                                                text: 'Timepasses',
                                                to: '/admin/monetization/time-passes',
                                            },
                                            {
                                                text: 'Edit',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    ],
                },
                // Circles
                {
                    path: 'circles',
                    component: AdminCircles,
                    meta: {
                        breadcrumbs: [
                            {
                                text: 'Manage',
                                to: '/admin',
                            },
                            {
                                text: 'Circles',
                            },
                        ],
                    },
                },
                {
                    path: '',
                    redirect: 'dashboard',
                },
            ],
        },
        {
            path: '/public',
            component: MainPublic,
            children: [
                {
                    path: 'event/:slug',
                    name: 'View Event',
                    component: CoViewPublicEvent,
                },
            ],
        },
        {
            path: '/legal',
            name: 'Legal',
            component: LegalBase,
            children: [
                {
                    path: 'privacy-policy',
                    name: 'Privacy Policy',
                    component: PrivacyPolicy,
                },
                {
                    path: 'terms-and-conditions',
                    name: 'Terms and Conditions',
                    component: TermsAndConditions,
                },
            ],
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/signup',
            name: 'signup',
            alias: '/register',
            component: Register,
            beforeEnter: checkIfAllowedToSingup,
        },
        {
            path: '/reset-password/:code',
            name: 'SetNewPassword',
            component: SetNewPassword,
        },
        {
            path: '/force-password-reset',
            name: 'ForceResetPassword',
            component: ForceResetPassword,
        },
        {
            path: '/reset-password',
            name: 'ResetPassword',
            component: ResetPassword,
        },
        {
            path: '/signup-success',
            name: 'SignUpSuccess',
            component: SignUpSuccess,
        },
        {
            name: 'RegisterV2',
            path: '/registerv2',
            component: RegisterV2,
            beforeEnter: checkIfInviteIsValidForPaid,
        },
        {
            name: 'PlanDetails',
            path: '/plans/:id',
            component: PlanDetails,
            beforeEnter: checkIfInviteIsValidForPaid,
        },
        {
            name: 'Plans',
            path: '/plans',
            component: Plans,
            beforeEnter: checkIfInviteIsValidForPaid,
        },
        {
            path: '/confirm-email/:code',
            name: 'EmailConfirmation',
            component: EmailConfirmation,
        },
        {
            path: '/suspended',
            name: 'Suspended',
            component: Suspended,
            beforeEnter: (to, from, next) => {
                if (store.state.space.Suspended) {
                    next();
                } else {
                    // reject the navigation
                    next('/');
                }
            },
        },
        {
            // =========================================================
            // WIFI authentication //public route!
            // redirected request by unifi will look like this :
            // /guest/s/default/?ap=80:2a:a8:83:15:03&id=f4:5c:89:a8:7d:d7&t=1553160350&url=http://www.spiegel.de%2f&ssid=testnet
            path: '/guest/s/:location',
            name: 'Captive',
            component: Captive,
        },
        {
            // =========================================================
            // WIFI authentication //public route!
            // success page
            path: '/guest/wifisuccess',
            name: 'CaptiveSuccess',
            component: CaptiveSuccess,
        },

        {
            // =========================================================
            // Community Screen //public route!
            path: '/public/screen',
            name: 'Community Screen',
            component: Screen,
        },
        {
            path: '/oauth2/exchange',
            name: 'Oauth2ExchangeCode',
            component: OAuth2Callback,
        },
        {
            path: '/oauth2/callback/kickasssquad',
            name: 'Oauth2CallbackKickasssquad',
            // redirect to the exchange page with query params
            beforeEnter: (to, from, next) => {
                next({ name: 'Oauth2ExchangeCode', query: to.query });
            },
        },
        {
            // catch all 404 routing
            // redirect all unresolved urls to dashboard
            path: '*',
            name: 'Catch all',
            // component: Error404
            beforeEnter: redirectorTo404,
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (store && store.state.space && store.state.space.Suspended && to.name !== 'AdminAccount') {
        if (
            store.state.me.Permissions &&
            store.state.me.Permissions.includes('space_admin') &&
            to.name !== 'AdminAccount' &&
            to.name !== 'Login'
        ) {
            next({ name: 'AdminAccount' });
        } else if (to.name !== 'Suspended' && to.name !== 'Login') {
            next({ name: 'Suspended' });
        } else {
            next();
        }
    } else {
        const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
        const isActiveUser = !store.state.me.Status || store.state.me.Status === 0 || store.state.me.Status === 1;
        const isAccountPath = to.path.includes('account');

        // log all page views for signed in users
        if (store && store.state && store.state.isLoggedIn) {
            logPageView(to);
        }

        // redirect suspended and cancelled users
        if (requiresAuth && !isActiveUser && !isAccountPath) {
            next({ name: 'CurrentUserPlanView' });
            EventBus.$emit('ERROR', { Message: "You're account isn't active" });
        } else {
            next();
        }
    }
});

export default router;
