














































































































































































import axios from 'axios';
import { get, remove } from 'lodash';
import { Event, User } from '../../../types/GlobalTypes';
import CoImage from '../../Atoms/co-image/CoImage.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoDate from '../co-date/CoDate.vue';
import CoPill from '../../Atoms/co-pill/CoPill.vue';
import CoConfirmation from '../co-confirmation/CoConfirmation.vue';
import { tr } from 'date-fns/locale';

export default {
    components: { CoSkeleton, CoHeadline, CoButton, CoText, CoImage, CoThumbnail, CoDate, CoPill, CoConfirmation },
    name: 'CoEvent',
    props: {
        event: {
            type: Object as () => Event,
            required: true,
            default: () => ({}),
        },
        variant: {
            type: String,
            validator: (value: string) => ['default', 'micro', 'list', 'full'].includes(value),
            default: 'default',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        me: {
            type: Object,
            required: false,
        },
        link: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            meLocal: get(this, 'me', null) || {
                ...get(this.$store, 'state.me', {}),
                ...get(this.$store, 'state.me.Profile', {}),
            },
            amIattending: false,
            amIorganising: false,
        };
    },
    computed: {
        isEventPast() {
            return this.event.EndDate < new Date().getTime() / 1000;
        },
    },
    mounted() {
        //set amIattending and amIorganising
        this.amIattending =
            this.meLocal &&
            this.event.Participants &&
            this.event.Participants.some((user) => user.ID === this.meLocal.ID);
        this.amIorganising =
            this.meLocal && this.event.Organizers && this.event.Organizers.some((user) => user.ID === this.meLocal.ID);
    },
    watch: {
        event: {
            handler(newValue, oldValue) {
                console.log('event changed', newValue);
                //reevaluate amIattending and amIorganising
                this.amIattending =
                    this.meLocal &&
                    this.event.Participants &&
                    this.event.Participants.some((user) => user.ID === this.meLocal.ID);
                this.amIorganising =
                    this.meLocal &&
                    this.event.Organizers &&
                    this.event.Organizers.some((user) => user.ID === this.meLocal.ID);
            },
            deep: true,
        },
    },
    methods: {
        get(object, path, defaultValue?: String) {
            return get(object, path, defaultValue);
        },
        attendEvent(event) {
            if (!this.meLocal) return;

            this.$emit('attend', event);
            this.event.Participants
                ? this.event.Participants.unshift(this.meLocal)
                : (this.event.Participants = [this.meLocal]);

            axios({
                method: 'POST',
                url: '/event/participant',
                data: {
                    EventID: this.event.ID,
                    ParticipantID: this.$store.state.me.ID,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {})
                .catch((error) => {
                    this.$emit('error', { message: 'Failed to attend event', error });
                    this.event.Participants.shift();
                });
        },
        unattendEvent(event) {
            if (!this.meLocal) return;
            this.event.Participants
                ? (this.event.Participants = this.event.Participants.filter((user: User) => {
                      return user.ID !== this.meLocal.ID;
                  }))
                : (this.event.Participants = null);
            this.$emit('unattend', event);
        },
    },
};
