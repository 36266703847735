





































































































































































































































import i18n from 'vue-i18n';
import EventBus from '@/eventBus';

import axios from 'axios';
import { get, uniq, map, uniqBy, upperFirst, toLower } from 'lodash';
import { format, addHours } from 'date-fns';
import CoImage from '@/components/Atoms/co-image/CoImage.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoPill from '@/components/Atoms/co-pill/CoPill.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoPeople from '@/components/Molecules/co-people/CoPeople.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoDragDropFileUpload from '@/components/Organisms/co-drag-drop-file-upload/CoDragDropFileUpload.vue';
import CoLoadingIndicator from '@/components/Atoms/co-loading-indicator/coLoadingIndicator.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoContentSection from '@/components/Organisms/сo-content-section/CoContentSection.vue';
import CoShare from '@/components/Organisms/co-share/CoShare.vue';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';

export default {
    name: 'EventView',
    i18n: {
        messages: {
            en: {
                labels: {
                    updateEvent: 'Update Event Details',
                    drafteventheadline: 'This event is in a draft mode',
                    eventVisability: 'Event Visability Settings',
                    publiceventheadline: 'This event is public and visible to everyone',
                    communityeventheadline: 'This event is visible to community members only',
                    draft: 'Draft',
                    public: 'Public',
                    communityOnly: 'Community Only',
                    closePreviewAsMember: 'Close Preview',
                    previewAsMember: 'Preview as Member',
                    addsection: 'Add Section',
                    textSection: 'Text Section',
                    imageSection: 'Image Section',
                    cancelattendance: 'Cancel attendance',
                    addaddress: 'Add a new address',
                    ticketrequiredwarning: 'This event requires a ticket.',
                    getticket: 'Get Ticket',
                    google: 'Google',
                    outlook: 'Outlook',
                    ics: 'ICS',
                    dragdropimage: 'Drag and drop an image here or click to upload',
                    suggestedsizes: 'Suggest size 1280x720px (16:9)',
                    savetocalendar: 'save to calendar',
                },

                messages: {
                    cancelmessage: 'Are you sure about this? This event would be much nicer with you!',
                    makePublic: 'Event will be visible to everyone outside the community. Are you sure?',
                    communityOnly: 'Event will be visible only to community members. Are you sure?',
                    deleteImageHeaderWarning: 'Are you sure you want to delete the header image?',
                    draftEvent: 'Event will be saved as a draft making it hidden. Are you sure?',
                },
            },
            de: {
                labels: {
                    addaddress: 'Neue Adresse hinzufügen',
                    updateEvent: 'Veranstaltungsdetails aktualisieren',
                    drafteventheadline: 'Dieses Event ist im Entwurfsmodus',
                    eventVisability: 'Veranstaltungssichtbarkeitseinstellungen',
                    publiceventheadline: 'Dieses Event ist öffentlich und für alle sichtbar',
                    communityeventheadline: 'Dieses Event ist nur für Community-Mitglieder sichtbar',
                    draft: 'Entwurf',
                    public: 'Öffentlich',
                    communityOnly: 'Nur für die Community',
                    closePreviewAsMember: 'Vorschau schließen',
                    previewAsMember: 'Vorschau als Mitglied',
                    addsection: 'Abschnitt hinzufügen',
                    textSection: 'Textabschnitt',
                    imageSection: 'Bildabschnitt',
                    cancelattendance: 'Teilnahme absagen',
                    ticketrequiredwarning: 'Für diese Veranstaltung ist ein Ticket erforderlich.',
                    getticket: 'Zum Ticket',

                    dragdropimage: 'Ziehen Sie ein Bild hierher oder klicken Sie hier, um es hochzuladen',
                    suggestedsizes: 'Empfohlene Größe 1280x720px (16:9)',
                    savetocalendar: 'in Kalender speichern',
                },

                messages: {
                    cancelmessage:
                        'Bist du dir sicher, dass du das willst? Diese @:labels.event wäre viel schöner mit dir!',
                    makePublic: 'Die Veranstaltung wird für alle außerhalb der Community sichtbar. Bist du sicher?',
                    communityOnly: 'Die Veranstaltung wird nur für Community-Mitglieder sichtbar. Bist du sicher?',
                    deleteImageHeaderWarning: 'Möchten Sie das Titelbild wirklich löschen?',
                    draftEvent: 'Die Veranstaltung wird als Entwurf gespeichert und versteckt. Bist du sicher?',
                },
            },
        },
    },
    components: {
        CoIcon,
        CoImage,
        CoText,
        CoButton,
        CoHeadline,
        CoThumbnail,
        CoDate,
        CoPill,
        CoCard,
        CoPeople,
        CoLink,
        CoSkeleton,
        CoDropdown,
        CoDropdownItem,
        CoContentSection,
        CoShare,
    },
    props: {
        slug: {
            type: String,
            required: false,
            default: 'test',
        },
        me: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            event: {},
            createdBy: {},
            preview: false,

            todayDate: new Date().toISOString().split('T')[0],
            startDate: '',
            startTime: '',

            endDate: '',
            endTime: '',

            loadingParticipants: false,
            participants: [],

            sectionsRerenderKey: 0,
            loadingEvent: false,

            types: [],
            selectedType: null,
            categories: [],
            locations: [],
            selectedLocation: '',
            customLocation: {
                Name: '',
                Address: '',
                ZipCode: '',
                City: '',
                Country: '',
            },
            tags: [],
        };
    },
    watch: {
        startDate(val) {
            // if end date is before start date, set end date to start date

            // convert to timestamp
            const start = new Date(val);
            const end = new Date(this.endDate);

            if (end < start) {
                this.endDate = val;
            }
        },

        startTime(val) {
            // if end time is before start time, set end time to start time
            // compose start date and time
            const start = new Date(`${this.startDate}T${this.startTime}`);
            const end = new Date(`${this.endDate}T${this.endTime}`);
            // convert to timestamp

            if (end <= start) {
                const tmp = addHours(start, 1);
                this.endTime = format(tmp, 'HH:mm');
            }
        },
    },
    computed: {
        noContent() // if no sections and no image and no tags return true
        {
            return (
                (!this.event.Sections || this.event.Sections.length === 0) &&
                !this.event.ImageURL &&
                (!this.event.Tags || this.event.Tags.length === 0)
            );
        },
        eventurl() {
            return `${window.location.origin}/events/${this.event.Slug}`;
        },
        isEventPast() {
            return this.event.EndDate < new Date().getTime() / 1000;
        },
        tagsAsText() {
            let tags = '';
            if (this.event.Tags) {
                this.event.Tags.forEach((tag) => {
                    tags += `<span data-type="cohashtag" class="cohashtag" data-id="${tag}" data-label="${tag}">${tag}</span> `;
                });
            }
            return tags;
        },
        allParticipants() {
            const p = get(this.event, 'Participants', []);
            const o = get(this.event, 'Organizers', []);
            // concat participants and organizers and remove duplicates
            let res = [...p, ...o];
            // add createdBy
            res = res.concat(this.event.CreatedBy);

            return uniq(res);
        },
        isSameDay() {
            const start = new Date(this.event.StartDate * 1000);
            const end = new Date(this.event.EndDate * 1000);
            return start.toDateString() === end.toDateString();
        },
        loactionWithNewLine() {
            if (!this.event || !this.event.Location) {
                return '';
            }
            return this.event.Location.replaceAll(', ', '<br/>');
        },
    },
    created() {
        this.getEvent();
    },

    methods: {
        gotoLogin() {
            this.$router.push(`/login?redirect=${this.$route.fullPath.replace('/public', '')}`);
        },
        addToCalendar(cal = 'google') {
            const calendarEvent = {
                richText: {
                    title: this.event.Title,
                    location: this.event.Location ? this.event.Location : null,
                    description: this.event.Description,
                    start: new Date(this.event.StartDate * 1000).toISOString(),
                    end: new Date(this.event.EndDate * 1000).toISOString(),
                },
                plainText: {
                    title: this.event.Title,
                    location: this.event.Location ? this.event.Location : null,
                    description: this.convertToPlain(this.event.Description),
                    start: new Date(this.event.StartDate * 1000).toISOString(),
                    end: new Date(this.event.EndDate * 1000).toISOString(),
                },
            };
            // add event url to the description
            if (this.event.FullURL) {
                calendarEvent.richText.description += `<br><br>============<br><br><a href="${this.event.FullURL}" target="_blank">Click here to join this event</a>`;
                calendarEvent.plainText.description += ` | Click here to join this event: ${this.event.FullURL}`;
            }

            // Then fetch the link
            if (cal === 'google') {
                window.open(google(calendarEvent.richText), '_blank');
            } else if (cal === 'outlook') {
                window.open(outlook(calendarEvent.richText), '_blank');
            } else if (cal === 'ics') {
                window.open(ics(calendarEvent.plainText), '_blank');
            }
        },
        convertToPlain(html) {
            const tempDivElement = document.createElement('div');
            tempDivElement.innerHTML = html;
            return tempDivElement.textContent || tempDivElement.innerText || '';
        },
        openTicketURL() {
            window.open(this.event.FullURL, '_blank');
        },
        parseEventDates() {
            const start = new Date(this.event.StartDate * 1000);
            this.startDate = start.toISOString().split('T')[0];
            this.startTime = format(start, 'HH:mm');

            const end = new Date(this.event.EndDate * 1000);
            this.endDate = end.toISOString().split('T')[0];
            this.endTime = format(end, 'HH:mm');
        },
        get,
        upperFirst,
        toLower,
        async getEvent() {
            this.loadingEvent = true;
            try {
                const response = await axios.get(`/public/event/${this.slug}`);
                this.event = response.data;

                if (get(this.event, 'Sections', []).length === 0 && this.event.Description) {
                    this.event.Sections = [
                        {
                            Type: 0,
                            Content: this.event.Description,
                        },
                    ];
                }

                this.organizers = get(this.event, 'Organizers', []);
                this.participants = get(this.event, 'Participants', []);

                // unify participants names
                this.organizers = this.organizers.map((p) => ({
                    ...p,
                    Name: `${p.FistName} ${p.LastName}`,
                    Role: 'organizer',
                }));

                // unify participants names
                this.participants = this.participants.map((p) => ({
                    ...p,
                    Name: `${p.FistName} ${p.LastName}`,
                    Role: 'participant',
                }));

                // combine participants and organizers
                this.participants = uniqBy([...this.organizers, ...this.participants], 'LastName');

                this.parseEventDates();
            } catch (error) {
                console.error(error);
                if (error.message.includes('404') || error.message.includes('403')) {
                    this.gotoLogin();
                }
            }
            this.loadingEvent = false;
        },
    },
};
