<!-- eslint-disable prettier/prettier -->
<style lang="less">
@import '~@/assets/less/variables.less';

.coapp-header {
    min-height: 4.25rem;
    height: 4.25rem;
    background: @co-lightgrey;
}

/* The max width is dependant on the container (more info below) */
.popover {
    max-width: 300px !important;
    /* Max Width of the popover (depending on the container!) */
    width: 100%;
}

//  toast notifications style

.vue-notification-wrapper {
    background: transparent;
}
.vue-app-notification {
    padding: 10px;
    margin: 8px;
    margin-bottom: 0px;
    // font-family: 'europa';
    border-radius: 7px;
    font-size: 13px;

    color: #212529;
    background: #ffffff;
    // border-left: 3px solid #ffffff;
    // border-left-color: #0055B8;
    color: #000;
    // border: none !important;

    &.warn {
        background: @yellow;
    }

    &.error {
        background: @error;
    }

    &.success {
        background: @success;
    }
}

.vue-notification-group {
    z-index: 2147483002 !important;
    top: 70px !important;
}

.b-toaster-top-right {
    top: 80px !important;
}

//is needed for deprecated search inputs that do not use CoInput component
.search-bar-wrapper {
    max-width: 1200px;
    padding-left: 10px;
    // border: 1px solid @purple;
    overflow: hidden;
    background: white;
    .b-icon {
        color: @ngreyd;
        margin-top: 1px;
        @media (max-width: 355px) {
            display: none !important;
        }
        // transform: scale(.8);
        &.search-focus {
            color: @purple;
        }
    }
    @media (min-width: 768px) {
        width: calc(100% - 270px);
    }
    @media (min-width: 1290px) {
        width: calc(100% - 520px);
    }
}

.search-input {
    background: transparent;
    color: @black;

    @media (max-width: 325px) {
        position: relative;
        left: -3px;
        width: calc(100% + 3px) !important;
    }
}

.search-input {
    &::placeholder {
        color: @ngreyd;
        opacity: 1;
        @media (max-width: 355px) {
            // color: transparent;
        }
    }
    &:focus &::placeholder {
        color: transparent;
        opacity: 1;
    }
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
</style>
<template>
    <div class="px-0 animate-blur" :path="path" :class="{ 'modal-blur': modalBlur }">
        <CoTopNavBarWeb
            v-if="!isInMobile"
            :spaceName="this.$store.state.space.Name"
            :logoURL="this.$store.state.space.LogoURL"
            :me="this.$store.state.me"
            @createpost="startPostCreation"
            @pausenotificationsclick="showPauseNotificationsModal"
            @unpausenotificationsclick="showDeletePauseNotificationsModal"
        />
        <!-- spacer is necessary for vertical spacing for all router views not using UserMainGrid.vue -->
        <div class="spacer"></div>
        <router-view ref="routerview" />
        <CoIslandCreateButton
            v-if="isInMobile"
            :marketIsOn="this.$store.state.space.MarkeplaceOn"
            :eventIsOn="isUserAllowedToCreateEvent"
            :isAdmin="admin"
            @createPost="startPostCreation"
            @createEvent="$router.push('/events/create')"
            @createMarketItem="$router.push('/submit-job')"
            @createPage="$router.push('/create/page')"
        />

        <div class="position-relative">
            <IntroModal
                :intro="this.$store.state.space['Intro']"
                v-if="this.$store && this.$store.state && this.$store.state.space && this.$store.state.space.Intro"
                @close="emitEventToIntercom"
            ></IntroModal>
            <IntroReminder
                :intro="this.$store.state.space['Intro']"
                v-if="this.$store.state.space['Intro'] != null"
            ></IntroReminder>
            <!-- <NotificationsCenter/> -->
        </div>

        <!-- pause notifications modal -->
        <b-modal
            id="pause-notificaions"
            ref="pause-notificaions"
            size="md"
            :title="$t('labels.notificationspause')"
            :ok-title="$t('labels.pause')"
            :cancel-title="$t('labels.cancel')"
            ok-variant="primary"
            @ok="setNotificationPause"
            centered
            no-fade
        >
            <b-overlay>
                <div class="mb-4">
                    <label :for="`picker-date`" class="label-align">{{ $t('labels.enddate') }}</label>
                    <b-form-datepicker
                        :label-no-date-selected="$t('labels.dateempty')"
                        :placeholder="$t('placeholders.date')"
                        :id="`picker-date`"
                        v-model="pauseUntil.date"
                        class="rounded-pill"
                    ></b-form-datepicker>
                </div>
                <div class="mb-4">
                    <label :for="`timepicker-from`" class="label-align">{{ $t('labels.time') }}</label>
                    <b-form-timepicker
                        :id="`timepicker-from`"
                        v-model="pauseUntil.time"
                        :locale="$language"
                        :hour12="false"
                        class="rounded-pill"
                        aria-label="from"
                    ></b-form-timepicker>
                </div>
                <label for="message" class="label-align">{{ $t('labels.awaynotice') }}</label>
                <Editor
                    id="message"
                    :placeholder="$t('placeholders.optional')"
                    taclass="m-0 comment-input"
                    rows="5"
                    class="customTiptap"
                    ref="tiptap"
                    v-model="pauseUntil.message"
                    @onFocus="onFocus = true"
                ></Editor>
            </b-overlay>
        </b-modal>

        <b-modal
            id="delete-pause-notificaions"
            ref="delete-pause-notificaions"
            size="md"
            :title="$t('labels.notificationspaused')"
            :ok-title="$t('labels.delete')"
            :ok-cancel="$t('labels.cancel')"
            ok-variant="danger"
            @ok="removeNotificationPause"
            centered
            no-fade
        >
            <b-overlay>
                <h3>{{ $t('labels.enddate') }}</h3>
                <h3>
                    <coDate :date="this.$store.state.me['NotificationsOffUntill']" format="exact" />
                </h3>
                <div class="mt-3">
                    <h3>{{ $t('labels.awaynotice') }}</h3>
                    <p
                        v-if="this.$store.state.me['NotificationsOffMessage']"
                        v-html="this.$store.state.me['NotificationsOffMessage']"
                    ></p>
                    <p v-else>-</p>
                </div>
            </b-overlay>
        </b-modal>

        <!-- privacy policy modal start -->
        <b-modal id="privacy-policy-main" hide-footer title="Imprint & Privacy Policy" size="xl">
            <div v-if="PrivacyPolicy" v-html="PrivacyPolicy.Content"></div>
        </b-modal>
        <!-- privacy policy modal end -->
        <!-- create post modal -->
        <CoPostEditor
            ref="postcreatemain"
            :user="{
                ...$store.state.me,
                ...$store.state.me.Profile,
            }"
            @created="addedPost"
        />
    </div>
</template>
<style lang="less" scoped>
@header-height: 4.25rem; //navbar height
@content-top-padding-desktop: 4.75rem; //spacing between navbar and content
@content-top-padding-mobile: 0;
.spacer {
    width: 100%;
    height: calc(@content-top-padding-desktop + @header-height);
}
@media screen and (max-width: 992px) {
    .spacer {
        height: calc(@header-height + @content-top-padding-mobile) !important;
    }
}
</style>
<script>
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vue from 'vue';
import axios from 'axios';
import EventBus from '@/eventBus.js';
import store from '@/store.js';
import VueNativeSock from 'vue-native-websocket';
import IntroReminder from '@/components/old-components/IntroReminder';
import VueIntercom from 'vue-intercom';
import CookieLaw from 'vue-cookie-law';

import { get } from 'lodash';

import CoRoundButton from '../../components/Atoms/co-round-button/CoRoundButton.vue';

import CoTopNavBarWeb from '../../components/Organisms/co-top-nav-bar-web/CoTopNavBarWeb.vue';
import CoPostEditor from '../../components/Organisms/co-post-editor/coPostEditor.vue';

import CoTopNavBarMobile from '../../components/Organisms/co-top-nav-bar-mobile/CoTopNavBarMobile.vue';
import CoIslandCreateButton from '../../components/Organisms/co-island-create-button/CoIslandCreateButton.vue';

import setCustomColors from '../../customColors';

Vue.use(VueIntercom, { appId: 'kttwojyk' });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

export default {
    name: 'Main',
    components: {
        IntroReminder,
        CookieLaw,
        CoRoundButton,
        CoTopNavBarMobile,
        CoIslandCreateButton,
        CoTopNavBarWeb,
        CoPostEditor,
    },
    data() {
        return {
            path: this.$router.currentRoute.path.split('/').join(' '),
            searchResults: false,
            searchAutocompletes: [],
            selectedAutocomlete: 0,
            search: '',
            searchFocus: false,
            burgerMenuOpen: false,
            admin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),
            modalBlur: false,

            pauseUntil: {
                date: null,
                time: null,
                message: '',
            },
            notificationsPaused: false,

            PrivacyPolicy: null,
            customMenuItems: [],

            isMarketOn: this.$store.state.space.MarkeplaceOn,
        };
    },
    watch: {
        // set the path parameter for conditional styling
        $route(to, from) {
            // check if url params 'm' is equal to 'mobile' and set the isInMobile flag in store
            if (to.query.m === 'mobile') {
                this.$store.commit('SET_IS_IN_MOBILE', true);
            } else if (to.query.m === 'desktop') {
                this.$store.commit('SET_IS_IN_MOBILE', false);
            }

            this.checkIfNotificationsPaused();
            this.path = to.path.split('/').join(' ');
            this.modalBlur = false;

            this.burgerMenuOpen = false;
        },
    },

    computed: {
        isAdmin() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin') === true) {
                return true;
            }
            return false;
        },
        isUserAllowedToCreateEvent() {
            return this.isAdmin || !this.$store.state.permissions.EventsCreateAdminsOnly;
        },
        isVisible() {
            return this.$intercom.visible;
        },
        me() {
            return this.$store.state.me;
        },
        query() {
            return this.$route.query.term;
        },
        isInMobile() {
            return get(this, '$store.state.isInMobile', false);
        },
    },
    beforeCreate() {
        this.$store
            .dispatch('getNewMessagesNumbers', null)
            .then((response) => {})
            .catch((error) => {
                console.info('getNewMessages', error);
            });
    },
    created() {
        if (this.$route.query.m === 'mobile') {
            this.$store.commit('SET_IS_IN_MOBILE', true);
        } else if (this.$route.query.m === 'desktop') {
            this.$store.commit('SET_IS_IN_MOBILE', false);
        }

        if (
            this.$store &&
            this.$store.state &&
            this.$store.state.space &&
            !this.$router.currentRoute.path.startsWith('/admin')
        ) {
            const primaryColor = this.$store.state.space.PrimaryColor.replace(/\s/g, '');
            const secondaryColor = this.$store.state.space.SecondaryColor.replace(/\s/g, '');
            const textColor = this.$store.state.space.TextColor.replace(/\s/g, '');

            const colorData = [
                {
                    name: 'purple',
                    value: primaryColor,
                },
                {
                    name: 'red',
                    value: secondaryColor,
                },
                {
                    name: 'text',
                    value: textColor,
                },
            ];

            try {
                setCustomColors(colorData);
            } catch (error) {
                try {
                    setCustomColors([
                        {
                            name: 'purple',
                            value: '#5100ff',
                        },
                        {
                            name: 'red',
                            value: '#fd6969',
                        },
                        {
                            name: 'text',
                            value: '#212529',
                        },
                    ]);
                } catch (error2) {
                    console.log(error2);
                }
                console.log(error);
            }
        }

        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const hour = date.getHours();
        const min = date.getMinutes();
        this.pauseUntil.date = `${year}-${month + 1}-${day}`;
        this.pauseUntil.time = `${hour}:${min}`;

        this.getPrivacyPolicy();
    },

    mounted() {
        if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
            this.$intercom.boot({
                user_id: this.$store.state.me.ID,
                name: this.$store.state.me.Profile.Name,
                email: this.$store.state.me.Email,
                spaceID: this.$store.state.space.ID,
                space: this.$store.state.space.Name,
                intro: this.$store.state.me.Intro,
                hide_default_launcher: this.$isMobile || this.isInMobile, // hide if Vue.prototype.$isMobile flag is set
            });
            this.$intercom.hide();
        }

        this.checkIfNotificationsPaused();
        this.$store.dispatch('getMe', null).then((response) => {
            this.initWebSocket();
        });

        this.$nextTick(function () {
            this.search = this.$route.query.term;
        });
        // event listener
        EventBus.$on(
            `MESSAGETOAST`,
            (payLoad) => {
                this.messageToast(payLoad);
            },
            this
        );
        EventBus.$on(
            'STARTNEWPOST',
            (payLoad) => {
                this.startPostCreation(payLoad);
            },
            this
        );
    },

    beforeDestroy() {
        this.$intercom.shutdown();
    },

    destroyed() {
        EventBus.$off('MESSAGETOAST');
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getPrivacyPolicy() {
            axios({
                method: 'GET',
                url: '/space/get-privacy-policy',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.PrivacyPolicy = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        emitEventToIntercom() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                this.$intercom.trackEvent('close-user-onboarding-modal');
            }
        },
        showPauseNotificationsModal() {
            this.$refs['pause-notificaions'].show();
        },
        showDeletePauseNotificationsModal() {
            this.$refs['delete-pause-notificaions'].show();
        },
        checkIfNotificationsPaused() {
            let now = new Date();
            now /= 1000;
            if (now < this.$store.state.me.NotificationsOffUntill) {
                this.notificationsPaused = true;
            } else {
                this.notificationsPaused = false;
            }
        },
        setNotificationPause() {
            const until = Date.parse(`${this.pauseUntil.date} ${this.pauseUntil.time}`) / 1000; // todo fix for utc
            const data = { pause: until, message: this.pauseUntil.message.replace(/<p><\/p>/g, '<br>') };
            axios({
                method: 'POST',
                url: '/me/pause-notifications',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$store.dispatch('getMe', null).then((response) => {
                        this.checkIfNotificationsPaused();
                    });
                })
                .catch((error) => {
                    console.info(error);
                });
        },

        removeNotificationPause() {
            axios({
                method: 'DELETE',
                url: '/me/pause-notifications',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$store.dispatch('getMe', null).then((response) => {
                        this.checkIfNotificationsPaused();
                    });
                })
                .catch((error) => {
                    console.info(error);
                    t;
                });
        },

        initWebSocket() {
            const token = localStorage.getItem('_authtoken');
            this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;

            Vue.use(VueNativeSock, `wss://api.collaborates.io/ws?token=${token}`, {
                store,
                reconnection: true, // (Boolean) whether to reconnect automatically (false)
                reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
                reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
            });
        },
        messageToast(msg) {
            const ids = JSON.stringify({ IDS: [{ ID: msg.from }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response.Users != null) {
                        msg.from = response.Users[0];
                    } else {
                        msg.from = {
                            ID: '',
                            Profile: {
                                ImageURL: '',
                                Name: 'User with hidden profile',
                            },
                        };
                    }
                    this.showToast(msg);
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },
        showToast(message) {
            // Use a shorter name for this.$createElement
            const h = this.$createElement;
            // Increment the toast count
            this.count++;
            // Create the title
            const vNodesTitle = h(
                'div',
                {
                    class: [
                        'd-flex',
                        'flex-grow-1',
                        'align-items-center',
                        'justify-content-between',
                        'text-dark',
                        'px-3',
                        'pt-3',
                    ],
                },
                [
                    h('div', { class: ['d-flex', 'align-items-center'] }, [
                        h('b-icon', {
                            props: { icon: 'chat-dots', scale: '1.3' },
                        }),
                        h('p', { class: ['my-auto', 'ml-2'] }, this.$t('labels.messagenew')),
                    ]),
                    h('p', { class: ['my-auto'] }, '13:22'),
                ]
            );

            // Create the vNodesMsg
            const vNodesMsg = h('div', { class: ['text-dark'] }, [
                h('h3', message.from.Profile.Name),
                // h('div', {props: {'v-html': message.text}}),
            ]);
            // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                noCloseButton: true,
                headerClass: 'border-0 p-0',
                bodyClass: 'p-3',
                toastClass: 'bg-white round-unify border-0 text-dark',
                to: `/messenger/${message.thread_id}`,
            });
        },
        // get custom menu items
        getCustomMenuItems() {
            axios
                .get('/dashboard/custom-menu-options/list')
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.customMenuItems = response.data;
                    } else {
                        this.customMenuItems = [];
                    }
                })
                .catch((error) => {
                    this.customMenuItems = [];
                    console.log(error);
                });
        },

        // start post creation
        startPostCreation(content = '') {
            this.$refs.postcreatemain.show({
                Text: content,
            });
        },

        // handle added posts depending on the current route
        addedPost(post) {
            this.$router.push('/?focuspostid=' + get(post, 'object.ID', '')); //highlight the new post
        },
    },
};
</script>
