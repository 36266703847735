






















































import { get, map, uniq } from 'lodash';
import axios from 'axios';
import { PropType } from 'vue';
import { User, Event, MarketItem, Page } from '@/types/GlobalTypes.ts';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoEvent from '../../Molecules/co-event/CoEvent.vue';
import CoMarketItem from '../../Molecules/co-marketitem/coMarketItem.vue';
import CoPage from '../../Molecules/co-page/CoPage.vue';
import CoLinkPreview from '../co-link-preview/CoLinkPreview.vue';

interface Fetch {
    ID: string;
    Slug: string;
    Type: string;
    Url: string;
}

export default {
    components: {
        CoHeadline,
        CoEvent,
        CoMarketItem,
        CoPage,
        CoLinkPreview,
    },
    name: 'CoContentPreview',
    props: {
        content: {
            type: Object as PropType<Event | MarketItem | Page>,
            default: null,
        },
        fetch: {
            type: Object as PropType<Fetch>,
            default: null as Fetch | null,
        },
        variant: {
            type: String,
            default: 'full',
            validator: (value) => ['default', 'full', 'list', 'micro'].includes(value),
        },
        link: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
            contentLoaded: null,
            fatchFailed: false,
        };
    },
    watch: {
        contentFetched: {
            handler(newVal) {
                this.contentLoaded = this.processContent();
            },
            deep: true,
        },
    },
    mounted() {
        this.processContent();
    },
    methods: {
        get(object, path, defaultValue) {
            return get(object, path, defaultValue);
        },
        fetchContent({ ID, Slug, Type }: Fetch) {
            return new Promise((resolve, reject) => {
                if (!(ID || Slug)) resolve(null);
                //check if Slug is url and extract Slug and Type
                try {
                    const url = new URL(Slug);
                    // check if url matches platform url
                    if (url.hostname !== window.location.hostname) {
                        resolve(null);
                    }

                    Slug = url.pathname.split('/').pop();
                    switch (url.pathname.split('/')[1].toLowerCase()) {
                        case 'project':
                        case 'channels':
                            Type = 'project';
                            break;
                        case 'profile':
                            Type = 'user';
                            break;
                        case 'event':
                        case 'events':
                            Type = 'event';
                            break;
                        case 'job':
                            Type = 'job';
                            break;
                        // case 'profile':
                        //     Type = 'user';
                        //     break;
                        default:
                            reject('unsupported content type');
                            break;
                    }
                } catch (error) {}

                axios({
                    method: ID ? 'POST' : 'GET',
                    url: ID ? `/${Type}/listbyids` : `/${Type}/by-slug/${Slug}`,
                    withCredentials: true,
                    data: ID ? { IDS: [{ ID: ID }] } : null,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve({
                            ...(get(response, 'data.Projects[0]') || get(response, 'data') || {}),
                        });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        fetchUsers(IDS: Array<String>) {
            return new Promise(async (resolve, reject) => {
                if (!IDS) resolve(null);
                axios({
                    method: 'POST',
                    url: '/user/listbyids',
                    data: { IDS: map(uniq(IDS), (id) => ({ ID: id })) },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        let users = get(response, 'data.Users', []);
                        users = map(users, (user) => ({
                            ...user,
                            ...get(user, 'Profile', {}),
                        }));
                        resolve(users);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        fetchInterestedUsers(marketItemID: string) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                if (!marketItemID) resolve([]);
                axios({
                    method: 'GET',
                    url: `/job/get-interested-users/${marketItemID}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        resolve(get(response, 'data.Interests', []));
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        fetchChannelInfo(channelID: string) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                if (!channelID) resolve({});
                resolve({});
                // to do: implement fetch channel info by channelID
                // axios({
                //     method: 'GET',
                //     url: '/job/get-interested-users/' + channelID,
                //     withCredentials: true,
                //     headers: {
                //         'Content-Type': 'application/json',
                //     },
                // }).then((response) => {
                //     resolve(get(response, 'data.Interests', []));
                // }).catch((error) => {
                //     reject(error);
                // });
            });
        },
        async processContent() {
            // if content is not defined or not fetched set return null
            // if (!this.contentFetched || !this.contentFetched.ID) return null;
            let data = {} as any;
            let contentFetched = null;
            let interestFetched = null;
            let channelFetched = null;
            const userList = [];
            let usersFetched = null;

            this.loading = true;
            // 1. checks if content needs to be fetched
            if (this.content) {
                data = {
                    ...this.content,
                };
            } else if (this.fetch) {
                // 2. if so fetch content
                try {
                    contentFetched = await this.fetchContent(this.fetch);
                } catch (error) {
                    console.error('error fetching content', error);
                }
                // if content is not supported set contentLoaded to contentFetched and return
                if (!contentFetched) {
                    this.fetchFailed = true;
                    this.contentLoaded = {};
                    return;
                }
                data = {
                    ...contentFetched,
                };
            }
            // normalize data.Type to lowercase
            data = {
                ...data,
                Type: get(data, 'Type', '').toLowerCase(),
            };
            // fetch additonal infos if needed
            // interested users if content is a market item
            if (data.Type === 'job') {
                interestFetched = await this.fetchInterestedUsers(data.ID);
                data = {
                    ...data,
                    Interests: interestFetched,
                };
            } else if (data.Type === 'project') {
                // channel info if content is a channel
                channelFetched = await this.fetchChannelInfo(data.ID);
                data = {
                    ...data,
                    Channel: channelFetched,
                };
            }
            // 3. checks if users need to be fetched and build a list of user ids to fetch
            // eslint-disable-next-line default-case
            switch (data.Type) {
                case 'event':
                    map(data.Participants || [], (participant) => {
                        userList.push(participant);
                    });
                    map(data.Organizers || [], (organizer) => {
                        userList.push(organizer);
                    });
                    break;
                case 'project':
                    map(data.Owner || [], (owner) => {
                        userList.push(owner);
                    });
                    map(data.Contributor || [], (owner) => {
                        userList.push(owner);
                    });
                    break;
            }
            // 4. fetch users
            if (userList.length > 0) {
                try {
                    usersFetched = await this.fetchUsers(userList);
                } catch (error) {
                    console.error('error fetching users', error);
                }
            }
            // 5. map content data according to type
            switch (data.Type) {
                case 'event':
                    data = {
                        ...data,
                        Images:
                            data.Images && typeof get(data, 'Images[0]') !== 'object'
                                ? map(data.Images, (image) => ({
                                      ImageURL: image,
                                  }))
                                : data.Images,
                        Participants: usersFetched || data.Participants,
                        Organizers: usersFetched
                            ? map(data.Organizers, (organizer) => ({
                                  ...usersFetched.find((user) => user.ID === organizer),
                              }))
                            : data.Organizers,
                        Categories: data.EventCategory
                            ? map(data.EventCategory, (category) => ({
                                  Name: category,
                                  Value: category,
                              }))
                            : [],
                        Types: data.EventType
                            ? map(data.EventType, (type) => ({
                                  Name: type,
                                  Value: type,
                              }))
                            : [],
                    };
                    break;
                case 'job':
                    data = {
                        ...data,
                        Images: data.ImageURL
                            ? [
                                  {
                                      ImageURL: data.ImageURL,
                                  },
                              ]
                            : [],
                    };
                    break;
                // eslint-disable-next-line no-fallthrough
                case 'project':
                    data = {
                        ...data,
                        Images: data.ImageURL && data.ImageURL.length > 0 ? [{ ImageURL: data.ImageURL[0] }] : [],
                        Owners:
                            usersFetched && data.Owner
                                ? map(data.Owner, (owner) => ({
                                      ...usersFetched.find((user) => user.ID === owner),
                                  }))
                                : [],
                        Contributors:
                            usersFetched && data.Contributor
                                ? map(data.Contributor, (contributor) => ({
                                      ...usersFetched.find((user) => user.ID === contributor),
                                  }))
                                : [],
                    };
                    break;
                // eslint-disable-next-line no-fallthrough
                default:
                    break;
            }

            // remove Images urls if they containe '/img/Platform_Gradient'
            data = {
                ...data,
                Images: get(data, 'Images', []).filter((image) => !image.ImageURL.includes('/img/Platform_Gradient')),
            };
            this.contentLoaded = data;
            this.loading = false;
        },
    },
};
