







































import i18n from 'vue-i18n';

import CoIcon from '../../../../Atoms/co-icon/CoIcon.vue';
import CoCardEvent from '../../../../Molecules/co-card-event/CoCardEvent.vue';
import { Event } from '../../../../Molecules/co-card-event/models.ts';
import CoButton from '../../../../Atoms/co-button/CoButton.vue';
import CoRoundButton from '../../../../Atoms/co-round-button/CoRoundButton.vue';

export default {
    i18n: {
        messages: {
            en: {
                eventsemptyheadline: 'Community Events',
                eventsemptysubline: 'Create events on your community platform.',
                eventsemptycta: 'Create an event',
            },
            de: {
                eventsemptyheadline: 'Community-Veranstaltungen',
                eventsemptysubline:
                    'Erstelle Veranstaltungen, um Wissen auszutauschen und gemeinsame Erfahrungen zu teilen.',
                eventsemptycta: 'Erstelle eine Veranstaltung',
            },
        },
    },
    name: 'ListEvents',
    components: {
        CoCardEvent,
        CoButton,
        CoIcon,
        CoRoundButton,
    },
    data() {
        return {
            feed: null,
            feedNextPage: null,

            loading: true,

            isCirclesOn: this.$store.state.isCirclesOn,
        };
    },
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        // this.scroll();
        // this.getEvents();
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        isDesktop() {
            return window.innerWidth >= 768;
        },
    },
    methods: {
        mapEventStucture(object) {
            const event = object.object;
            const spaceItem = object.space;
            const e: Event = {
                id: event.ID,
                title: event.Title,
                description: event.Description,
                start: event.StartDate,
                end: event.EndDate,
                slug: event.Slug,
                image: event.ImageURL,
                // set if me.ID is in event.Attendees
                attend: event.Participants.includes(this.me.ID),
            };

            if (spaceItem) {
                e.space = {
                    id: spaceItem.ID,
                    name: spaceItem.Name,
                    favicon: spaceItem.FaviconURL,
                    primaryColor: spaceItem.PrimaryColor,
                };
            }

            return e;
        },
        getEvents(next) {
            this.loading = true;
            this.$store
                .dispatch('getEventsV2', this.feedNextPage)
                .then((response) => {
                    if (response.objects) {
                        response.objects.forEach(function (entry) {
                            // entry.object["StartDate"] = entry.object["StartDate"]+"000";
                            // entry.object["EndDate"] = entry.object["EndDate"]+"000";
                            this.feed.push(entry);
                        }, this);
                        this.feed.sort((a, b) => a.object.StartDate - b.object.StartDate);
                        if (response.next != '' && response.next != null) {
                            this.feedNextPage = response.next;
                        } else {
                            this.feedNextPage = null;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                    // todo show error
                });
        },

        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 30) {
                    if (this.feedNextPage != null && !this.loading) {
                        this.getEvents();
                    }
                }
            };
        },
    },
};
