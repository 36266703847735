


























































import Vue from 'vue';
import axios from 'axios';
import i18n from 'vue-i18n';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoDate from '../../Molecules/co-date/CoDate.vue';
import CoLink from '../../Atoms/co-link/CoLink.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

export default {
    components: { CoCard, CoThumbnail, CoIcon, CoDate, CoLink, CoSkeleton },
    i18n: {
        messages: {
            en: {
                reacted: 'reacted to {subject}',
                commented: 'commented on {subject}',
                interested: 'is interested in {subject}',
                voted: 'voted on {subject}', // legacy
                other: 'interacted with {subject}',
                'mention-in-post': 'mentioned you in {subject}',
                'mention-in-comment': 'mentioned you in a comment on {subject}',
                'new-activity-on-post': 'new activity on {subject}',
                'new-event-participant': 'participanting in {subject}',
                'new-page-follower': 'started following {subject}',
                'new-owner-of-page': 'You are now co-owner of {subject}',
                'new-contributor-of-page': 'You are now a contributor of {subject}',
                'new-update-in-page-i-follow': 'posted a new update in {subject}',
            },
            de: {
                reacted: 'reagierte auf {subject}',
                commented: 'kommentierte {subject}',
                interested: 'interessiert sich für {subject}',
                voted: 'hat über {subject} abgestimmt', // legacy
                other: 'hat mit {subject} interagiert',
                'mention-in-post': 'hat dich in {subject} erwähnt',
                'mention-in-comment': 'hat dich in einem Kommentar zu {subject} erwähnt',
                'new-activity-on-post': 'neue Aktivität auf {subject}',
                'new-event-participant': 'nimmt an {subject} teil',
                'new-page-follower': 'folgt {subject}',
                'new-owner-of-page': 'Du bist jetzt Miteigentümer*in von {subject}',
                'new-contributor-of-page': 'Du bist jetzt ein Mitwirkende*r von {subject}',
                'new-update-in-page-i-follow': 'hat ein neues Update in {subject} veröffentlicht',
            },
        },
    },
    name: 'CoNotification',
    props: {
        item: {
            type: Object,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            author: null,
            object: null,
            subject: null,
            subjectType: '',
            subjectTypeName: '',
            eventType: '',
            activityType: '',
            activityText: '',
            isNew: !(this.item && this.item.Seen),
            hideNotification: false,
        };
    },
    created() {
        if (!this.item) return;

        this.getAuthor(this.item.Event.InitiatorID);
        this.eventType = this.item.Event.Type;
        this.subjectType = this.item.Event.TargetType;

        switch (this.subjectType) {
            case 'post':
                this.getPost(this.item.Event.TargetID);
                this.subjectTypeName = this.$t('labels.post');
                break;
            case 'event':
                this.getEvent(this.item.Event.TargetID, this.eventType);
                this.subjectTypeName = this.$t('labels.event');
                break;
            case 'project':
            case 'page':
                this.getPage(this.item.Event.TargetID, this.eventType);
                this.subjectTypeName = this.$t('labels.page');
                break;
            case 'projectupdate':
            case 'projectUpdate':
            case 'ProjectUpdate':
            case 'project-update':
            case 'page-update':
                this.getUpdate(this.item.Event.TargetID);
                this.subjectTypeName = this.$t('labels.post');
                break;
            case 'market-item':
            case 'job':
                this.getMarket(this.item.Event.TargetID, this.eventType);
                this.subjectTypeName = this.$t('labels.marketitem');
                break;
            default:
                break;
        }

        switch (this.eventType) {
            case 'reaction-created':
            case 'reaction-removed':
            case 'reaction-to-post':
                this.activityType = 'reacted';
                break;
            case 'post-upvote-create':
            case 'post-downvote-create':
                this.activityType = 'voted';
                break;
            case 'comment-create':
                this.activityType = 'commented';
                break;
            case 'job-interest-create':
                this.activityType = 'interested';
                break;
            default:
                this.activityType = this.eventType;
                break;
        }
    },
    methods: {
        getAuthor(id) {
            axios({
                method: 'POST',
                url: '/user/listbyids',
                data: { IDS: [{ ID: id }] },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.Users && response.data.Users[0]) {
                        this.author = response.data.Users[0];
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getPost(id) {
            axios({
                method: 'GET',
                url: `/post/by-id/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.subject = {
                        Title: this.getPostText(response.data.Text),
                        Slug: `/post/${response.data.Slug}?commentid=${response.data.ID}`,
                    };
                })
                .catch((error) => {
                    this.hideNotification = true;
                    console.log(error);
                });
        },

        getUpdate(id) {
            axios({
                method: 'GET',
                url: `/project/update/by-id/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.subject = {
                        Title: this.getPostText(response.data.Text),
                        Slug: `/project-update/${response.data.ID}`,
                    };
                })
                .catch((error) => {
                    this.hideNotification = true;
                    console.log(error);
                    // todo show error
                });
        },

        getPage(id, eventtype) {
            axios({
                method: 'POST',
                url: '/project/listbyids',
                data: { IDS: [{ ID: id }] },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (!response.data.Projects || !response.data.Projects[0]) return;
                    this.subject = {
                        Title: response.data.Projects[0].Title,
                        Slug: `/project/${response.data.Projects[0].Slug}`,
                    };

                    if (eventtype.includes('reaction') || eventtype.includes('comment')) {
                        this.subject.Slug = `/page-as-post/${response.data.Slug}`;
                    }
                })
                .catch((error) => {
                    this.hideNotification = true;
                    console.log('Project not found\n', error);
                });
        },

        getMarket(id, eventtype) {
            axios({
                method: 'GET',
                url: `/job/by-id/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (!response.data) return;
                    this.subject = {
                        Title: response.data.Title,
                        Slug: `/job/${response.data.Slug}`,
                    };

                    if (eventtype.includes('reaction') || eventtype.includes('comment')) {
                        this.subject.Slug = `/market-item-as-post/${response.data.Slug}`;
                    }
                })
                .catch((error) => {
                    this.hideNotification = true;
                    console.log('Market item not found', error);
                });
        },
        getEvent(id, eventtype) {
            axios({
                method: 'GET',
                url: `/event/by-id/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (!response.data) return;
                    this.subject = {
                        Title: response.data.Title,
                        Slug: `/event/${response.data.Slug}`,
                    };

                    if (eventtype.includes('reaction') || eventtype.includes('comment')) {
                        this.subject.Slug = `/event-as-post/${response.data.Slug}`;
                    }
                })
                .catch((error) => {
                    this.hideNotification = true;
                    console.log('Event not found', error);
                });
        },

        getPostText(html) {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body && doc.body.textContent ? `${doc.body.textContent.substring(0, 30)}...` : '';
        },

        markAsSeen(item) {
            const that = this;
            axios({
                method: 'POST',
                url: '/notification',
                data: {
                    NotificationID: item.ID,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.item.Seen = true;
                    this.isNew = false;
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                })
                .finally(() => {
                    //update the notification count
                    if (that.$store) that.$store.dispatch('getGetNewNotifications');
                });
        },

        itemClick(event) {
            event.preventDefault();
            event.stopPropagation();
            this.$emit('click', this.item);
            if (this.isNew) this.markAsSeen(this.item);
            if (this.subject && this.subject.Slug) this.$router.push(this.subject.Slug);
        },
    },
};
